<template>
    <div
            class="modal fade cart-control"
            :id="`domain_${index}`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
    >
        <div class="modal-dialog" role="document">
            <span class="gradient-piece-before-modal"></span>
            <div class="modal-content cart-modal">
                <div class="modal-body">
                    <p class="lead">
                        Eliminare il dominio <b>{{ domain.name }}</b> dal carrello ?
                    </p>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-2 col-md-offset-7">
                            <button
                                    type="button"
                                    class="btn btn-default"
                                    data-dismiss="modal"
                            >
                                Annulla
                            </button>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <a>
                                <form action="/cart/remove-domain" method="GET">
                                    <input type="hidden" name="_token" :value="token" />
                                    <input type="hidden" name="domain" :value="index" />
                                    <button
                                            type="submit"
                                            class="btn btn-danger btn-block remove-hosting-domain"
                                    >
                                        Rimuovi
                                    </button>
                                </form>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <span class="gradient-piece-after-modal"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RemoveDomain",
        props: ["index", "domain", "token"],
        mounted() {},
    };
</script>
