// /**
//  * SEZIONE CHE GESTISCE GLI EVENTI GRAFICI DELLE SELECT GRAFICHE
//  */

// var current_scroll = 0;

// var browser = {
//     macosx: /mac/i.test(navigator.platform),
//     mobile: /android|webos|iphone|ipad|ipoda|blackberry/i.test(navigator.userAgent),
//     overlay: null,
//     scroll: null,
//     scrolls: [],
//     webkit: /webkit/i.test(navigator.userAgent) && !/edge\/\d+/i.test(navigator.userAgent)
// };

// (function (document, window, $) {
//     "use strict";

//     var lastScrollTop = 0;
//     $(window).scroll(function(e){
//         var st = $(this).scrollTop();
//         if (st > lastScrollTop){
//             $.FormWhois.defaults.already_scroll = true;
//         }
//         lastScrollTop = st;
//     });

//     // $(window).load(function(){
//         // if(($(window).height() * 1) < 670)
//         // {
//         //     if($.FormWhois.defaults.already_scroll === false)
//         //     {
//         //         var top = $(".form-whois button").offset().top + 40;
//         //         $("html,body").animate({scrollTop: top}, 1000);
//         //     }
//         // }
//     // });

//     $(document).ready(function () {
//         FORMWHOIS.LoadTLD();

//         $.FormWhois.defaults.placeholder_whois = $("#your_domain_whois").val();
//         $.FormWhois.defaults.placeholder_whois_def = $("#your_domain_whois_def").val();
//         $("#your_domain_whois").remove();
//         $("#your_domain_whois_def").remove();

//         // $('.form-whois [data-type=sld]').focus();

//         var page = $(".form-whois").attr("action");
//         var from = $(".form-whois").find('[data-type="sld"]').attr('data-from');

//         if(page === "/domini/nuovi-gtld/index.php")
//         {
//             $.FormWhois.defaults.current_page = "Nuovi gTLD";
//         }
//         if(page === "/index.php")
//         {
//             $.FormWhois.defaults.current_page = "Home Page";
//         }

//         if(navigator.userAgent.toLowerCase().indexOf("ipad") > -1)
//         {
//             $('.form-whois [data-type=sld]').addClass("ipad");
//         }

//         $('.form-whois [data-type=sld]').keydown(function(e) {
//             var value = e.which;
//             if(value === 13) // TASTO INVIO
//             {
//                 // console.log(from);
//                 var closest = $(this).closest(".form-whois");
//                 // $('.spinner_indominio').show();
//                 $(closest).submit();
//             }
//         });

//         $('.form-whois [data-type=sld]').bind('input', function() {
//             var start = this.selectionStart, end = this.selectionEnd;
//             var closest = $(this).closest(".form-whois");
//             if(typeof $(this).val() !== "undefined")
//             {
//                 var tld = $(closest).find("[data-type=tld]").val();
//                 FORMWHOIS.verifyDomain($(closest).find("[data-type=sld]").val(), tld, closest);
//             } else {

//             }
//             try
//             {
//                 if($(this).val().trim().length === 0)
//                 {
//                     $(closest).find("[data-type=tld]").show();
//                 }
//             } catch (e)
//             {

//             }

//             if($("html").hasClass("ie8")) {

//             } else {
//                 this.setSelectionRange(start, end);
//             }
//         });

//         $(document).on('click', '.form-whois [data-type=button]', function(e) {
//             e.preventDefault();

//             var inCart = false;

//             if(typeof $(form).attr("data-cart") !== 'undefined')
//             {
//               if($(form).attr("data-cart") === "true")
//               {
//                 inCart = true;
//               }
//             }

//             if (inCart) {

//               // console.log(from);
//               // console.log(inCart);

//               var sld = $.FormWhois.defaults.sld;
//               var tld = $.FormWhois.defaults.tld;

//               if($.FormWhois.defaults.subdomain !== "" && $.FormWhois.defaults.subdomain !== null)
//               {
//                   $.FormWhois.defaults.subdomain = $.FormWhois.defaults.subdomain.replace(".", "");
//                   tld = "." + $.FormWhois.defaults.subdomain + tld;
//               }

//               var domain = sld + tld;

//               $('input[name="sld"]').val(sld);
//               $('input[name="tld"]').val(tld);

//               var params = {
//                 'sld'    : sld,
//                 'tld'    : tld,
//                 'domain' : domain
//               };

//               // console.log(params);

//               showSpinningModal(params);
//               $(this).off(e);
//             } else {
//               $(this).closest(".form-whois").submit();
//             }
//         });

//         $(document).on('submit', ".form-whois",function(e){
//             var form = $(this);
//             var href = $(form).attr("action");
//             e.preventDefault();
//             try
//             {
//                 clearInterval($.FormWhois.defaults.setIntervalHideError);
//             } catch (e) {

//             }

//             var inCart = false;
//             var fromSpotlight = false;

//             if(typeof $(form).attr("data-cart") !== 'undefined')
//             {
//               if($(form).attr("data-cart") === "true")
//               {
//                 inCart = true;
//               }
//             }

//             if(typeof $(form).attr("data-spotlight") !== 'undefined')
//             {
//               if($(form).attr("data-spotlight") === "true")
//               {
//                 fromSpotlight = true;
//               }
//             }

//             if (inCart) {

//               // console.log(from);
//               // console.log(inCart);

//               var sld = $.FormWhois.defaults.sld;
//               var tld = $.FormWhois.defaults.tld;

//               if($.FormWhois.defaults.subdomain !== "" && $.FormWhois.defaults.subdomain !== null)
//               {
//                   $.FormWhois.defaults.subdomain = $.FormWhois.defaults.subdomain.replace(".", "");
//                   tld = "." + $.FormWhois.defaults.subdomain + tld;
//               }

//               var domain = sld + tld;

//               $('input[name="sld"]').val(sld);
//               $('input[name="tld"]').val(tld);

//               var params = {
//                 'sld'    : sld,
//                 'tld'    : tld,
//                 'domain' : domain
//               };

//               // console.log(params);

//   showSpinningModal(params);
//               $(this).off(e);
//             }

//             else {

//               if($(form).find("[data-type=button]").attr("data-status") === "enabled")
//               {
//                   if($.FormWhois.defaults.find_domain === false)
//                   {
//                       $.FormWhois.defaults.find_domain = true;
//                       $(form).find('.message-box .message-text-bottom').empty().hide();
//                       var tld = $(form).find('[data-type=tld]').val();
//                       var sld = $(form).find('[data-type=sld]').val();
//                       var status = FORMWHOIS.verifyDomain(sld, tld, form);
//                       if(status)
//                       {
//                           tld = $.FormWhois.defaults.tld;
//                           if($.FormWhois.defaults.subdomain !== "")
//                           {
//                               $.FormWhois.defaults.subdomain = $.FormWhois.defaults.subdomain.replace(".", "");
//                               tld = "." + $.FormWhois.defaults.subdomain + tld;
//                           }

//                           window.location.href = href + "?sld=" + $.FormWhois.defaults.sld + "&tld=" + tld;
//                       } else {
//                           $.FormWhois.defaults.find_domain = false;
//                           if($(form).find('.message-box .message-text-bottom').html().length === 0)
//                           {
//                               $(form).find('.message-box .message-text-bottom').html("È necessario inserire un nome di dominio valido").show();
//                           }
//                       }
//                   }
//               } else {
//                   $.FormWhois.defaults.find_domain = false;
//                   $(form).find('.message-box .message-text-bottom').html("È necessario inserire un nome di dominio valido").show();
//               }
//           }
//             FORMWHOIS.hideMessageError(form);
//         });
//     });
//     $.FormWhois = function () { };
//     $.FormWhois.defaults = {
//         current_page: null,
//         valid_tlds: "",
//         gen_tlds: "",
//         new_gtlds: "",
//         italy_tlds: "",
//         all_tlds: "",
//         domain: null,
//         accent_array: ['á','à','â','ã','å','ä','æ','ç','è','é','ë','ê','í','ì','î','ï','ñ','ó','ò','ô','ø','õ','ö','ú','ù','û','ü'],
//         special_chars: [33,34,35,36,37,38,39,40,41,42,44,47,58,59,60,61,62,63,64,91,92,93,94,95,123,124,125,126,163,167,176,231,8364],
//         special_character: ["!", "_", "@","#", "$", "%", "€", "{", "}", "^", "&", "+", "*", "§", "?", "'", "£", "(", ")", ",", "ç", ";", ":", "\\","\"", "/", "|", "[", "]","°", "=","<",">"],
//         regex_replace_accentati: /[!áàâãåäæçèéëêíìîïñóòôøõöúùûü@#$%^&+*§?"'£(),;:\[\]°=\\/|<>]/g,
//         regex_replace: /[!_@#$%€{}^&+*§?"'£(),ç;:\[\]°=\\/|<>]/g,
//         estensioni_suggerite_whois: [".it", ".com", ".org", ".net", ".eu"],
//         accenti: [186, 192, 191, 221, 222],
//         suggested_tlds: null,
//         last_whois: null,
//         domain_find: null,
//         find_domain: false,
//         all_find_domain: [],
//         check_verify_item: [],
//         additional_extension_cookie: [],
//         transfer_page: false,
//         promotion_domain: null,
//         tld: null,
//         sld: null,
//         subdomain: null,
//         getDomain: null,
//         DomainArray: null,
//         domini_accentati: false,//IMPOSTARE SU true PER FAR REGISTRARE DOMINI ACCENTATI
//         body: $("body"),
//         already_scroll: false,
//         emoji: [
//             '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
//             '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
//             '\ud83d[\ude80-\udeff]'  // U+1F680 to U+1F6FF
//         ],
//         placeholder_whois: null,
//         placeholder_whois_def: null,
//         setIntervalHideError: null
//     };
//     $.FormWhois.prototype = {
//         LoadTLD: function() {
//             $.getJSON(
//                 //@TODO qui va inserito il webservice creato su indominio
//                 '/Tlds.php'
//                 // 'https://api.keliweb.it/domain/ws.alltlds.php'
//             ).done(function (data) {
//                 $.FormWhois.defaults.valid_tlds = data['valid_tlds'];
//                 $.FormWhois.defaults.gen_tlds = data['generic'];
//                 $.FormWhois.defaults.geo_tlds = data['geographic'];
//                 $.FormWhois.defaults.all_tlds = data['alltld'];
//             });
//         },
//         hideMessageError: function(closest) {
//             $.FormWhois.defaults.setIntervalHideError = setTimeout(function () {
//                 $(closest).find('.message-box .message-text-bottom').hide("slow");
//             }, 5000);
//         },
//         verifyDomain: function(sld_input, tld_input, closest) {
//             try
//             {
//                 clearInterval($.FormWhois.defaults.setIntervalHideError);
//             } catch (e) {

//             }
//             if(typeof $(closest).attr("data-from") !== "undefined")
//             {
//                 if($(closest).attr("data-from") === "spotlight")
//                 {
//                     sld_input = sld_input.replace(".","");
//                 }
//             }

//             var status = true;
//             var show_suggest = true;
//             sld_input = sld_input.toLowerCase();
//             sld_input.replace(/\s+/g,"");
//             sld_input = sld_input.replace("..",".");
//             sld_input = sld_input.replace("`","");
//             sld_input = sld_input.replace("~","");
//             sld_input = sld_input.replace(/ +?/g, '');

//             $.FormWhois.defaults.domain = sld_input;
//             FORMWHOIS.removeWWW();

//             FORMWHOIS.SanitizeDomain($.FormWhois.defaults.domain, tld_input);
//             /**
//              * DOPO AVER ESAMINATO ALCUNE PROBLEMATICHE, SI E' DECISO DI ABBASSARE DA 4 A 3 DI DOMINI REGISTRABILI/TRASFERIBILI DA QUESTA PAGINA.
//              * QUESTA PRECISION è STATA PRESA IN ACCORDO CON MEDURI E GIUSEPPE DE LUCA
//              * VEDASI EMAIL 26/06/2017
//              */
//             if($.FormWhois.defaults.sld.length < 3)
//             {
//                 status = false;
//                 show_suggest = false;
//                 $(closest).find('[data-type=button]').attr('data-status', "disabled");
//             }
//             if($.FormWhois.defaults.domain.length === 0)
//             {
//                 status = false;
//                 show_suggest = false;

//                 $(closest).find('[data-type=button]').attr('data-status', "enabled");
//                 $(closest).find('.message-box .message-text-bottom').html('').hide();
//             }
//             if(status)
//             {
//                 if($.FormWhois.defaults.domain.substring(0, 1) === "-")
//                 {
//                     status = false;
//                     show_suggest = false;
//                     $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                     $(closest).find('.message-box .message-text-bottom').html("È necessario inserire un nome di dominio valido").show();
//                 }
//             }
//             if(status)
//             {
//                 //rimuovo emoticon
//                 var str = $.FormWhois.defaults.domain.replace(new RegExp($.FormWhois.defaults.emoji.join('|'), 'g'), '');
//                 // $(".domain-search").val(str);
//                 $(closest).find('[data-type=sld]').val(str);
//             }
//             if(status)
//             {
//                 if($.FormWhois.defaults.domain.length > 63)
//                 {
//                     status = false;
//                     show_suggest = false;
//                     $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                     $(closest).find('.message-box .message-text-bottom').html("È necessario inserire un nome di dominio valido").show();
//                     var dominio = $.FormWhois.defaults.domain;
//                     dominio = dominio.substring(0,63);
//                     // $(".domain-search").val(dominio);
//                     $(closest).find('[data-type=sld]').val(dominio);
//                 }
//             }
//             if(status)
//             {
//                 var special = FORMWHOIS.SpecialChars($.FormWhois.defaults.domain);
//                 var accenti = FORMWHOIS.DominiAccentati($.FormWhois.defaults.domain);
//                 if(special)
//                 {
//                     status = false;
//                     show_suggest = false;
//                     $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                     $(closest).find('.message-box .message-text-bottom').html("Carattere non consentito!").show();
//                 } else if(accenti)
//                 {
//                     status = false;
//                     show_suggest = false;
//                     $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                     $(closest).find('.message-box .message-text-bottom').html("Per registrare un dominio accentato <a href='mailto:domini@domini.it'>contattaci</a>!").show();
//                 }
//                 else {
//                     status = true;
//                     show_suggest = true;
//                     $(closest).find('.message-box .message-text-bottom').html('').hide();
//                     $(closest).find('[data-type=button]').attr('data-status', "enabled");
//                 }

//                 $.FormWhois.defaults.last_whois = null;
//                 var second_tld = "non è valido. Consulta una <a href='/domini/nuovi-gtld/' target='_new'>lista di estensioni</a> o <a href='mailto:domini@domini.it'>contattaci</a>!";
//                 var first_tld = "Il TLD";

//                 //NASCONDO LA SELECT DEI TLD

//                 // var d = $(".domain-search").val();
//                 var d = $(closest).find('[data-type=sld]').val();
//                 d = d.split(".");
//                 if(d.length > 1) {
//                     $(closest).find('[data-type=tld]').hide();
//                 } else {
//                     $(closest).find('[data-type=tld]').show();
//                 }

//                 //NASCONDO LA SELECT DEI TLD

//                 if(status) {
//                     if ($.FormWhois.defaults.DomainArray.length > 1) {
//                         show_suggest = false;
//                         var exist_subdomain = false;
//                         if ($.FormWhois.defaults.subdomain !== "." && $.FormWhois.defaults.subdomain !== "" && $.FormWhois.defaults.tld !== ".uk" && $.FormWhois.defaults.subdomain !== "co") {
//                             $.FormWhois.defaults.subdomain = $.FormWhois.defaults.subdomain.replace(".", "");
//                             pos = -1;
//                             var italy = $.FormWhois.defaults.italy_tlds;
//                             for (var i = 0; i < italy.length; i++) {
//                                 (function (i) {
//                                     if (italy[i]["tld"] === "." + $.FormWhois.defaults.subdomain) {
//                                         pos = 1;
//                                     }
//                                 }(i));

//                                 if (i === italy.length - 1) {
//                                     if (pos === -1) {
//                                         $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                                         $(closest).find('.message-box .message-text-bottom').html("Il dominio con estensione: <b>" + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld + "</b> " + second_tld).show();
//                                         status = false;
//                                     } else {
//                                         exist_subdomain = true;
//                                         status = true;
//                                     }
//                                 }
//                             }

//                             if (status === true) {
//                                 if ($.FormWhois.defaults.tld !== ".") {
//                                     var pos = $.FormWhois.defaults.valid_tlds.indexOf($.FormWhois.defaults.tld);
//                                     if (pos === -1) {
//                                         $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                                         $(closest).find('.message-box .message-text-bottom').html("Il dominio con estensione: <b>" + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld + "</b> " + second_tld).show();
//                                         status = false;
//                                     } else {
//                                         status = true;
//                                     }
//                                     if (exist_subdomain === true && status === true && $.FormWhois.defaults.tld !== ".it") {
//                                         $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                                         $(closest).find('.message-box .message-text-bottom').html("Questo dominio accetta come estensione gTLD solo il <b>.it</b>").show();
//                                     }
//                                 }
//                             }

//                             if($.FormWhois.defaults.DomainArray.length > 3) {
//                                 $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                                 $(closest).find('.message-box .message-text-bottom').html("Il dominio con estensione: <b>" + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld + "</b> " + second_tld).show();
//                                 status = false;
//                             }
//                         }
//                         else {
//                             if ($.FormWhois.defaults.tld !== "." && $.FormWhois.defaults.tld.length > 1) {
//                                 var position = -1;
//                                 for(var a = 0; a < $.FormWhois.defaults.valid_tlds.length;a++)
//                                 {
//                                     (function(a) {
//                                         var tl = $.FormWhois.defaults.valid_tlds[a];
//                                         if(tl === $.FormWhois.defaults.tld)
//                                         {
//                                             position = 0;
//                                         }

//                                     }(a));
//                                     if(a === $.FormWhois.defaults.valid_tlds.length - 1)
//                                     {
//                                         if (position === -1) {
//                                             status = false;
//                                             $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                                             $(closest).find('.message-box .message-text-bottom').html(first_tld + ' <b>' + $.FormWhois.defaults.tld + '</b> ' + second_tld).show();
//                                         } else {
//                                             status = true;
//                                             $(closest).find('.message-box .message-text-bottom').html('').hide();
//                                             $(closest).find('[data-type=button]').attr('data-status', "enabled");
//                                         }
//                                     }
//                                 }
//                             } else {
//                                 status = false;
//                                 $(closest).find('.message-box .message-text-bottom').html('').hide();
//                                 $(closest).find('[data-type=button]').attr('data-status', "enabled");
//                             }

//                             if($.FormWhois.defaults.DomainArray.length > 3) {
//                                 $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                                 $(closest).find('.message-box .message-text-bottom').html("Il dominio inserito " + second_tld).show();
//                                 status = false;
//                             }
//                         }
//                     } else if($.FormWhois.defaults.DomainArray.length > 3) {
//                         $(closest).find('[data-type=button]').attr('data-status', "disabled");
//                         $(closest).find('.message-box .message-text-bottom').html("Il dominio con estensione: <b>" + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld + "</b> " + second_tld).show();
//                         status = false;
//                     } else {
//                         $.FormWhois.defaults.domain = $.FormWhois.defaults.domain + $.FormWhois.defaults.tld;
//                         $(closest).find('[data-type=tld]').show();
//                     }
//                 } else {
//                     $.FormWhois.defaults.find_domain = false;
//                 }
//             } else {
//                 $(closest).find('[data-type=button]').attr('data-status', "disabled");
//             }
//             if(show_suggest)
//             {
//                 //FORMWHOIS.DominiSuggeritiInputWhois();
//             }

//             if(typeof $.FormWhois.defaults.domain !== "undefined")
//             {
//                 if($.FormWhois.defaults.domain.length === 0)
//                 {
//                     $.FormWhois.defaults.find_domain = false;
//                 }
//             }

//             $(closest).find("[data-type=sld]").val($.FormWhois.defaults.domain);
//             FORMWHOIS.hideMessageError(closest);
//             return status;
//         },
//         removeWWW: function() {
//             var dom = $.FormWhois.defaults.domain.split(".");
//             if(dom.length>0)
//             {
//                 if(dom[0] === "www")
//                 {
//                     $.FormWhois.defaults.domain = $.FormWhois.defaults.domain.replace("www.", "");
//                 }
//             }
//         },
//         SanitizeDomain: function(dominio, tld_input) {
//             try {
//                 dominio = dominio.toLowerCase().trim();
//                 dominio = dominio.replace("--","-");
//                 dominio = dominio.replace("http://", "");
//                 dominio = dominio.replace("https://", "");
//                 var pathArray = dominio.split('/');
//                 dominio = dominio.replace("www", "");
//                 dominio = dominio.replace("/", "");
//                 for (var i = 0; i < pathArray.length; i++) {
//                     dominio = pathArray[0];
//                     break;
//                 }
//                 var reg_point = /../g;
//                 if (reg_point.test(dominio)) {
//                     dominio = dominio.replace("..", ".");
//                 }

//                 if($.FormWhois.defaults.domini_accentati === null) {
//                     for (var k = 0;k < $.FormWhois.defaults.accenti.length; k++){
//                         dominio = dominio.replace($.FormWhois.defaults.regex_replace_accentati, ""); //RIMUOVO CARATTERI NON AMMESSI IN UN DOMINIO
//                     }
//                 }

//                 // console.log(tld_input);

//                 var dom = dominio.split(".");
//                 var len = dom.length - 1;
//                 $.FormWhois.defaults.tld = "." + dom[len];
//                 $.FormWhois.defaults.sld = dom[0];
//                 $.FormWhois.defaults.subdomain = "";
//                 if (len === 0) {
//                     $.FormWhois.defaults.tld = tld_input;
//                     // dom[1] = tld_input;
//                     // len = dom.length - 1;
//                 }
//                 if(dom.length === 1)
//                 {
//                     dom[1] = tld_input;
//                 }
//                 if (len === 2) {
//                     $.FormWhois.defaults.subdomain = "." + dom[1];
//                 }
//                 if (len > 2) {//SE VENGONO AGGIUNTI OLTRE 3 LIVELLI DI DOMINIO
//                     $.FormWhois.defaults.sld = "";
//                     for (var j = 0; j < len; j++) {
//                         $.FormWhois.defaults.sld += dom[j];
//                     }
//                 }
//                 $.FormWhois.defaults.DomainArray = dom;
//                 var reg = /www./g;
//                 if (reg.test(dominio)) { //RIMUOVO IL WWW INSERITO DAL CLIENTE
//                     dominio = dominio.replace("www.", "");
//                 }
//                 dominio = dominio.replace(" ", "");

//                 if ($.FormWhois.defaults.domini_accentati === true) {
//                     dominio = dominio.replace(/[!_àèéòìù@€\{}#$%^&+*§?"'£()ç,;:\[\]°=\\/|<>]/g, ""); //RIMUOVO CARATTERI NON AMMESSI IN UN DOMINIO
//                 } else {
//                     dominio = dominio.replace(/[!_@#$%€\{}^&+*§?"'£(),ç;:\[\]°=\\/|<>]/g, ""); //RIMUOVO CARATTERI NON AMMESSI IN UN DOMINIO
//                 }
//             } catch (e){
//                 // HandleErrors(null, null, null, e, "SanitizeDomain("+dominio+")", "/js-site/pages/domain/domain.js", "212");
//                 console.log(e);
//             }
//             $.FormWhois.defaults.getDomain = $.FormWhois.defaults.sld + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld;
//             $.FormWhois.defaults.domain = dominio;
//             return dominio;
//         },
//         /**
//          *
//          * @param char
//          * @returns {boolean}
//          * @constructor
//          */
//         SpecialChars: function(char){
//             var ret = false;
//             var in_array = "";
//             if(typeof char !== "undefined")
//             {
//                 if(char.length > 1)
//                 {
//                     for(var i = 0; i < char.length;i++)
//                     {
//                         in_array = $.FormWhois.defaults.special_character.indexOf(char[i]) > -1;
//                         if(in_array){
//                             ret = true;
//                             break;
//                         }
//                     }
//                 } else {
//                     in_array = $.FormWhois.defaults.special_chars.indexOf(char) > -1;
//                     if(in_array){
//                         ret = true;
//                     }
//                 }
//             }
//             return ret;
//         },
//         /**
//          *
//          * @param char
//          * @returns {boolean}
//          * @constructor
//          */
//         DominiAccentati: function(char){
//             var ret = false;

//             if(typeof char !== "undefined")
//             {
//                 if ($.FormWhois.defaults.domini_accentati === false) {
//                     var k = 0;
//                     if(char.length > 1)
//                     {
//                         for (var i = 0; i < char.length; i++) {
//                             for (k = 0; k < $.FormWhois.defaults.accent_array.length; k++) {
//                                 if (char[i] === $.FormWhois.defaults.accent_array[k]) {
//                                     ret = true;
//                                     break;
//                                 }
//                             }
//                         }
//                     } else {
//                         for (k = 0; k < $.FormWhois.defaults.accent_array.length; k++) {
//                             if (char === $.FormWhois.defaults.accent_array[k]) {
//                                 ret = true;
//                                 break;
//                             }
//                         }
//                     }
//                 }
//             }
//             return ret;
//         },
//         createArrayCategoriesTld: function() {
//             $.FormWhois.defaults.category_tlds = [];
//             var geo = [];
//             geo["geographic"] = $.FormWhois.defaults.geo_tlds;
//             $.FormWhois.defaults.category_tlds.push(geo);

//             var generic = [];
//             generic["generic"] = ($.FormWhois.defaults.gen_tlds);
//             $.FormWhois.defaults.category_tlds.push(generic);

//             var new_gtlds = [];
//             new_gtlds["new_gtlds"] = ($.FormWhois.defaults.new_gtlds);
//             $.FormWhois.defaults.category_tlds.push(new_gtlds);
//         },
//         /**
//          * @param number
//          * @param decimals
//          * @param dec_point
//          * @param thousands_sep
//          * @returns {*}
//          */
//         numberFormat: function(number, decimals, dec_point, thousands_sep) {
//             number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
//             var n = !isFinite(+number) ? 0 : +number,
//                 prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
//                 sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
//                 dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
//                 s = '',
//                 toFixedFix = function(n, prec) {
//                     var k = Math.pow(10, prec);
//                     return '' + (Math.round(n * k) / k).toFixed(prec);
//                 };
//             s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
//             if (s[0].length > 3) {
//                 s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
//             }
//             if ((s[1] || '').length < prec) {
//                 s[1] = s[1] || '';
//                 s[1] += new Array(prec - s[1].length + 1).join('0');
//             }
//             return s.join(dec);
//         },
//     }
// }(document, window, jQuery));

// var FORMWHOIS = new $.FormWhois;


/**
 * SEZIONE CHE GESTISCE GLI EVENTI GRAFICI DELLE SELECT GRAFICHE
 */

var current_scroll = 0;

var browser = {
    macosx: /mac/i.test(navigator.platform),
    mobile: /android|webos|iphone|ipad|ipod|blackberry/i.test(navigator.userAgent),
    overlay: null,
    scroll: null,
    scrolls: [],
    webkit: /webkit/i.test(navigator.userAgent) && !/edge\/\d+/i.test(navigator.userAgent)
};

(function (document, window, $) {
    "use strict";

    $(window).resize(function () {
        FORMWHOIS.ManagePlaceholder();
        FORMWHOIS.resizeSelect();
    });

    var lastScrollTop = 0;
    $(window).scroll(function (e) {
        var st = $(this).scrollTop();
        if (st > lastScrollTop) {
            $.FormWhois.defaults.already_scroll = true;
        }
        lastScrollTop = st;
    });

    $(window).on("load", function (e) {
        // if(($(window).height() * 1) < 670)
        // {
        //     if($.FormWhois.defaults.already_scroll === false)
        //     {
        //         var top = $("#find").offset().top + 40;
        //         $("html,body").animate({scrollTop: top}, 1000);
        //     }
        // }
        let w_body = $("body").width() * 1;
        let input = $("input#domain");
        let input_placeholder = $(input).attr("data-place");

        if (typeof input_placeholder !== "undefined") {
            if (w_body < 530) {
                $(input).attr("placeholder", $.FormWhois.defaults.placeholder_whois);
            } else {
                $(input).attr("placeholder", input_placeholder);
            }
        } else {

            $.FormWhois.defaults.placeholder_whois = $("#your_domain_whois").val();
            $.FormWhois.defaults.placeholder_whois_def = $("#your_domain_whois_def").val();

            if (browser["mobile"]) {
                if (typeof $.FormWhois.defaults.placeholder_whois === "undefined") {
                    if ($.FormWhois.defaults.locale === "en_US") {
                        $.FormWhois.defaults.placeholder_whois = "yourdomain.com";
                    } else {
                        // $.FormWhois.defaults.placeholder_whois = "" +
                        //     "" +
                        //     ".it";
                        $.FormWhois.defaults.placeholder_whois = "tuodominio";
                    }

                }
                $(input).attr("placeholder", $.FormWhois.defaults.placeholder_whois);
            } else {
                $(input).attr("placeholder", $.FormWhois.defaults.placeholder_whois_def);
            }

            if ($(input).attr("data-form-type") === "home") {
                if ($.FormWhois.defaults.locale === "en_US") {
                    $.FormWhois.defaults.placeholder_whois = "yourdomain.com";
                } else {
                    $.FormWhois.defaults.placeholder_whois = "tuodominio.it";
                }
            }

            FORMWHOIS.ManagePlaceholder();
        }
    });

    $(document).ready(function () {
        FORMWHOIS.LoadTLD();
        FORMWHOIS.resizeSelect();
        $.FormWhois.defaults.locale = $("#LOCALE_K").val();
        $.FormWhois.defaults.placeholder_whois = $("#your_domain_whois").val();
        $.FormWhois.defaults.placeholder_whois_def = $("#your_domain_whois_def").val();
        $("#your_domain_whois").remove();
        $("#your_domain_whois_def").remove();

        // FORMWHOIS.ManagePlaceholder();
        var autofocus = true;
        try {
            let url = window.location.href;
            if (url.indexOf("scegliere-dominio") !== -1) {
                autofocus = false;
            }
        } catch (e) {

        }

        if (!browser["mobile"]) {
            if (typeof $("#no_focus").val() !== "undefined") {
                if ($("#no_focus").val() === "true") {
                    autofocus = false;
                }
            }
            if (autofocus) {
                $("#form input#domain").focus();
            }
        }
        let input_placeholder = $("input#domain").attr("data-place");

        if (typeof input_placeholder !== "undefined") {
            $("input#domain").attr("placeholder", input_placeholder);
        } else {

            if (browser["mobile"]) {
                $('#select-tld').addClass("show");
                $('#form .selection').addClass("not-show-mobile");
                $("input#domain").attr("placeholder", $.FormWhois.defaults.placeholder_whois);
            } else {
                $("input#domain").attr("placeholder", $.FormWhois.defaults.placeholder_whois_def);
            }

            FORMWHOIS.ManagePlaceholder();

            if ($("#form input#domain").attr("data-form-type") === "home") {
                if ($.FormWhois.defaults.locale === "en_US") {
                    $.FormWhois.defaults.placeholder_whois = "yourdomain.com";
                } else {
                    $.FormWhois.defaults.placeholder_whois = "tuodominio.it";
                }
                $("input#domain").attr("placeholder", $.FormWhois.defaults.placeholder_whois);
            }
        }

        var page = $("#form").attr("action");
        if (page === "/domini/nuovi-gtld/index.php") {
            $.FormWhois.defaults.current_page = "Nuovi gTLD";
        }
        if (page === "/index.php" || page === "index.php") {
            $.FormWhois.defaults.current_page = "Home Page";
        }
        if (page === "/domini/registrazione-dominio.php") {
            $.FormWhois.defaults.current_page = "Registrazione dominio";
        }
        if (page === "/domini/trasferimento-dominio.php") {
            $.FormWhois.defaults.current_page = "Trasferimento dominio";
        }

        if (navigator.userAgent.toLowerCase().indexOf("ipad") > -1) {
            $("input#domain").addClass("ipad");
        }

        // $('#form #domain').keydown(function(e) {
        $(document).on('keydown', '#form #domain', function (e) {
            var value = e.which;
            if (value === 13) // TASTO INVIO
            {
                $('#form').submit();
            }
        });

        $('#form #domain').focusout('input', function () {
            var tld = $('#form .selection .item.selected').attr("data-val");
            if (browser["mobile"]) {
                tld = $("#select-tld").val();
            }
            FORMWHOIS.verifyDomain($('#form #domain').val(), tld);
        });

        // $('#form #domain').bind('input', function() {
        $(document).on('bind input', '#form #domain', function () {
            var start = this.selectionStart, end = this.selectionEnd;
            if (typeof $(this).val() !== "undefined") {
                var tld = $('#form .selection .item.selected').attr("data-val");
                if (browser["mobile"]) {
                    tld = $("#select-tld").val();
                }
                // FORMWHOIS.verifyDomain($('#form #domain').val(), tld);
                FORMWHOIS.veryDomainOnInput($('#form #domain').val(), tld);
            } else {

            }
            try {
                if ($(this).val().trim().length === 0) {
                    if (browser["mobile"]) {
                        $('#select-tld').show();
                        $('#form .selection').hide();
                        $('#form #whois-tld-list').hide();
                    } else {
                        $('#form .selection').show();
                        $('#select-tld').hide();
                        $('#form #whois-tld-list').show();
                    }
                }
            } catch (e) {

            }

            if ($("html").hasClass("ie8")) {

            } else {
                this.setSelectionRange(start, end);
            }
        });

        $(document).on('click', '#searchDomain', function () {
            $('#form').submit();
        });

        $(document).on('submit', "#form", function (e) {
            e.preventDefault();
            try {
                clearInterval($.FormWhois.defaults.setIntervalHideError);
            } catch (e) {

            }
            if ($("#searchDomain").attr("data-status") === "enabled") {
                if ($.FormWhois.defaults.find_domain === false) {
                    $.FormWhois.defaults.find_domain = true;
                    $('.error_domain').empty().hide();
                    var tld = $('#form .selection .item.selected').attr("data-val");
                    if (browser["mobile"]) {
                        tld = $("#select-tld").val();
                    }
                    var status = FORMWHOIS.verifyDomain($('#form #domain').val(), tld);

                    try {
                        var _d = $.FormWhois.defaults.getDomain;
                        _d = _d.split(".");
                        if (_d.length === 1) {
                            $.FormWhois.defaults.getDomain = $.FormWhois.defaults.getDomain + ".it";
                        }
                    } catch (e) {

                    }

                    //KELIGAEVENT.addGenericEvent($.FormWhois.defaults.current_page, 'Click Pulsante Cerca Dominio', $.FormWhois.defaults.getDomain);

                    if (status) {
                        let url = $(this).attr("action");
                        let method = $(this).attr("method");
                        let domain = $.FormWhois.defaults.getDomain;
                        let sld = $.FormWhois.defaults.sld;
                        let tld = $.FormWhois.defaults.tld;

                        let params = {
                            'sld': sld,
                            'tld': tld,
                            'domain': domain
                        };

                        window.location.href = url + "?sld=" + sld + "&tld=" + tld;
                    } else {
                        $.FormWhois.defaults.find_domain = false;
                        if ($('.error_domain').html().length === 0) {
                            $('.error_domain').html($("body").data('domain_is_not_valid')).show();
                        }
                    }
                }
            } else {
                $.FormWhois.defaults.find_domain = false;
                $('.error_domain').html($("body").data('domain_is_not_valid')).show();
            }
            FORMWHOIS.hideMessageError();
        });
    });
    $.FormWhois = function () { };
    $.FormWhois.defaults = {
        current_page: null,
        valid_tlds: "",
        gen_tlds: "",
        new_gtlds: "",
        italy_tlds: "",
        all_tlds: "",
        domain: null,
        accent_array: ['á', 'à', 'â', 'ã', 'å', 'ä', 'æ', 'ç', 'è', 'é', 'ë', 'ê', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'ø', 'õ', 'ö', 'ú', 'ù', 'û', 'ü'],
        special_chars: [33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 44, 47, 58, 59, 60, 61, 62, 63, 64, 91, 92, 93, 94, 95, 123, 124, 125, 126, 163, 167, 176, 231, 8364],
        special_character: ["!", "_", "@", "#", "$", "%", "€", "{", "}", "^", "&", "+", "*", "§", "?", "'", "£", "(", ")", ",", "ç", ";", ":", "\\", "\"", "/", "|", "[", "]", "°", "=", "<", ">"],
        regex_replace_accentati: /[!áàâãåäæçèéëêíìîïñóòôøõöúùûü@#$%^&+*§?"'£(),;:\[\]°=\\/|<>]/g,
        regex_replace: /[!_@#$%€{}^&+*§?"'£(),ç;:\[\]°=\\/|<>]/g,
        estensioni_suggerite_whois: [".it", ".com", ".org", ".net", ".eu"],
        accenti: [186, 192, 191, 221, 222],
        suggested_tlds: null,
        last_whois: null,
        domain_find: null,
        find_domain: false,
        all_find_domain: [],
        check_verify_item: [],
        additional_extension_cookie: [],
        transfer_page: false,
        promotion_domain: null,
        tld: null,
        sld: null,
        subdomain: null,
        getDomain: null,
        DomainArray: null,
        domini_accentati: false,//IMPOSTARE SU true PER FAR REGISTRARE DOMINI ACCENTATI
        body: $("body"),
        already_scroll: false,
        emoji: [
            '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
            '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
            '\ud83d[\ude80-\udeff]'  // U+1F680 to U+1F6FF
        ],
        placeholder_whois: null,
        placeholder_whois_def: null,
        setIntervalHideError: null,
        locale: null,
        /**
         * Impostare la variabile adaptive_faq
         */
        adaptive_faq: false
    };
    $.FormWhois.prototype = {
        LoadTLD: function () {
            $.getJSON(
                '/Tlds.php'
            ).done(function (data) {
                $.FormWhois.defaults.valid_tlds = data['valid_tlds'];
                $.FormWhois.defaults.gen_tlds = data['generic'];
                $.FormWhois.defaults.geo_tlds = data['geographic'];
                $.FormWhois.defaults.all_tlds = data['alltld'];
            });
        },
        ManagePlaceholder: function () {
            let w_body = $("body").width() * 1;
            let input = $("input#domain");
            let custom_placeholder = false;
            if (typeof $(input).attr("data-form-type") !== "undefined") {
                if ($(input).attr("data-form-type") === "transfer_domain") {
                    custom_placeholder = true;
                }
            }

            let input_placeholder = $(input).attr("data-place");

            if (typeof input_placeholder !== "undefined") {
                if (w_body < 530) {
                    $(input).attr("placeholder", $.FormWhois.defaults.placeholder_whois);
                } else {
                    $(input).attr("placeholder", input_placeholder);
                }
            } else {
                if (custom_placeholder === false) {
                    if (w_body < 992) {
                        $(input).attr("placeholder", $.FormWhois.defaults.placeholder_whois);
                    } else {
                        $(input).attr("placeholder", $.FormWhois.defaults.placeholder_whois_def);
                    }
                }
            }
        },
        hideMessageError: function () {
            $.FormWhois.defaults.setIntervalHideError = setTimeout(function () {
                $('.error_domain').hide("slow");
            }, 5000);
        },
        veryDomainOnInput: function (sld_input, tld_input) {
            var status = true;
            var show_suggest = true;
            sld_input = sld_input.toLowerCase();
            sld_input.replace(/\s+/g, "");
            sld_input = sld_input.replace("..", ".");
            sld_input = sld_input.replace(/ +?/g, '');

            /**
             * CARATTERI CIRILLICI
             *
             * /[а-яА-ЯЁё]/.test(term)
             */

            $.FormWhois.defaults.domain = sld_input;
            FORMWHOIS.removeWWW();

            FORMWHOIS.SanitizeDomain($.FormWhois.defaults.domain, tld_input);
            /**
             * DOPO AVER ESAMINATO ALCUNE PROBLEMATICHE, SI E' DECISO DI ABBASSARE DA 4 A 3 DI DOMINI REGISTRABILI/TRASFERIBILI DA QUESTA PAGINA.
             * QUESTA PRECISION è STATA PRESA IN ACCORDO CON MEDURI E GIUSEPPE DE LUCA
             * VEDASI EMAIL 26/06/2017
             */
            if ($.FormWhois.defaults.sld.length < 3) {
                status = false;
                show_suggest = false;
                $('#searchDomain').attr('data-status', "disabled");
            }
            if ($.FormWhois.defaults.domain.length === 0) {
                status = false;
                show_suggest = false;

                $('#searchDomain').attr('data-status', "enabled");
                $('.error_domain').html('').hide();
            }
            if (status) {
                if ($.FormWhois.defaults.domain.substring(0, 1) === "-") {
                    status = false;
                    show_suggest = false;
                    $('#searchDomain').attr('data-status', "disabled");
                    $('.error_domain').html($("body").data('domain_is_not_valid')).show();
                }
            }
            if (status) {
                //rimuovo emoticon
                var str = $.FormWhois.defaults.domain.replace(new RegExp($.FormWhois.defaults.emoji.join('|'), 'g'), '');
                $("#domain").val(str);
            }
            if (status) {
                if ($.FormWhois.defaults.domain.length > 63) {
                    status = false;
                    show_suggest = false;
                    $('#searchDomain').attr('data-status', "disabled");
                    $('.error_domain').html($("body").data('domain_is_not_valid')).show();
                    var dominio = $.FormWhois.defaults.domain;
                    dominio = dominio.substring(0, 63);
                    $("#domain").val(dominio);
                }
            }
            if (status) {
                var special = FORMWHOIS.SpecialChars($.FormWhois.defaults.domain);
                var accenti = FORMWHOIS.DominiAccentati($.FormWhois.defaults.domain);
                if (special) {
                    status = false;
                    show_suggest = false;
                    $('#searchDomain').attr('data-status', "disabled");
                    $('.error_domain').html($("body").data('character_not_available')).show();
                } else if (accenti) {
                    status = false;
                    show_suggest = false;
                    $('#searchDomain').attr('data-status', "disabled");
                    $('.error_domain').html($("body").data('domini_accentati')).show();
                }
                else {
                    status = true;
                    show_suggest = true;
                    $('.error_domain').html('').hide();
                    $('#searchDomain').attr('data-status', "enabled");
                }

                $.FormWhois.defaults.last_whois = null;
                var second_tld = $("body").data('second_value');
                var first_tld = $("body").data('first_value');

                //NASCONDO LA SELECT DEI TLD

                var d = $("#domain").val();
                d = d.split(".");
                if (d.length > 1) {
                    $('#form .selection').hide();

                    if (browser["mobile"]) {
                        $('#select-tld').hide();
                    } else {
                        $('#form .selection').hide();
                    }
                } else {
                    // $('#form .selection').show();

                    if (browser["mobile"]) {
                        $('#select-tld').show();
                        $('#form .selection').hide();
                        $('#form #whois-tld-list').hide();
                    } else {
                        $('#form .selection').show();
                        $('#form #whois-tld-list').show();
                    }
                }
            }
        },
        verifyDomain: function (sld_input, tld_input) {
            try {
                clearInterval($.FormWhois.defaults.setIntervalHideError);
            } catch (e) {

            }
            var status = true;
            var show_suggest = true;
            sld_input = sld_input.toLowerCase();
            sld_input.replace(/\s+/g, "");
            sld_input = sld_input.replace("..", ".");
            sld_input = sld_input.replace(/ +?/g, '');

            /**
             * CARATTERI CIRILLICI
             *
             * /[а-яА-ЯЁё]/.test(term)
             */

            $.FormWhois.defaults.domain = sld_input;
            FORMWHOIS.removeWWW();

            FORMWHOIS.SanitizeDomain($.FormWhois.defaults.domain, tld_input);
            /**
             * DOPO AVER ESAMINATO ALCUNE PROBLEMATICHE, SI E' DECISO DI ABBASSARE DA 4 A 3 DI DOMINI REGISTRABILI/TRASFERIBILI DA QUESTA PAGINA.
             * QUESTA PRECISION è STATA PRESA IN ACCORDO CON MEDURI E GIUSEPPE DE LUCA
             * VEDASI EMAIL 26/06/2017
             */
            if ($.FormWhois.defaults.sld.length < 3) {
                status = false;
                show_suggest = false;
                $('#searchDomain').attr('data-status', "disabled");
            }
            if ($.FormWhois.defaults.domain.length === 0) {
                status = false;
                show_suggest = false;

                $('#searchDomain').attr('data-status', "enabled");
                $('.error_domain').html('').hide();
            }
            if (status) {
                if ($.FormWhois.defaults.domain.substring(0, 1) === "-") {
                    status = false;
                    show_suggest = false;
                    $('#searchDomain').attr('data-status', "disabled");
                    $('.error_domain').html($("body").data('domain_is_not_valid')).show();
                }
            }
            if (status) {
                //rimuovo emoticon
                var str = $.FormWhois.defaults.domain.replace(new RegExp($.FormWhois.defaults.emoji.join('|'), 'g'), '');
                $("#domain").val(str);
            }
            if (status) {
                if ($.FormWhois.defaults.domain.length > 63) {
                    status = false;
                    show_suggest = false;
                    $('#searchDomain').attr('data-status', "disabled");
                    $('.error_domain').html($("body").data('domain_is_not_valid')).show();
                    var dominio = $.FormWhois.defaults.domain;
                    dominio = dominio.substring(0, 63);
                    $("#domain").val(dominio);
                }
            }
            if (status) {
                var special = FORMWHOIS.SpecialChars($.FormWhois.defaults.domain);
                var accenti = FORMWHOIS.DominiAccentati($.FormWhois.defaults.domain);
                if (special) {
                    status = false;
                    show_suggest = false;
                    $('#searchDomain').attr('data-status', "disabled");
                    $('.error_domain').html($("body").data('character_not_available')).show();
                } else if (accenti) {
                    status = false;
                    show_suggest = false;
                    $('#searchDomain').attr('data-status', "disabled");
                    $('.error_domain').html($("body").data('domini_accentati')).show();
                }
                else {
                    status = true;
                    show_suggest = true;
                    $('.error_domain').html('').hide();
                    $('#searchDomain').attr('data-status', "enabled");
                }

                $.FormWhois.defaults.last_whois = null;
                var second_tld = $("body").data('second_value');
                var first_tld = $("body").data('first_value');

                //NASCONDO LA SELECT DEI TLD

                var d = $("#domain").val();
                d = d.split(".");
                if (d.length > 1) {
                    $('#form .selection').hide();

                    if (browser["mobile"]) {
                        $('#select-tld').hide();
                    } else {
                        $('#form .selection').hide();
                    }
                } else {
                    // $('#form .selection').show();

                    if (browser["mobile"]) {
                        $('#select-tld').show();
                        $('#form .selection').hide();
                        $('#form #whois-tld-list').hide();
                    } else {
                        $('#form .selection').show();
                        $('#form #whois-tld-list').show();
                    }
                }

                //NASCONDO LA SELECT DEI TLD

                if (status) {
                    if ($.FormWhois.defaults.DomainArray.length > 1) {
                        show_suggest = false;
                        var exist_subdomain = false;
                        if ($.FormWhois.defaults.subdomain !== "." && $.FormWhois.defaults.subdomain !== "" && $.FormWhois.defaults.tld !== ".uk" && $.FormWhois.defaults.subdomain !== "co") {
                            $.FormWhois.defaults.subdomain = $.FormWhois.defaults.subdomain.replace(".", "");
                            pos = -1;
                            var italy = $.FormWhois.defaults.italy_tlds;
                            for (var i = 0; i < italy.length; i++) {
                                (function (i) {
                                    if (italy[i]["tld"] === "." + $.FormWhois.defaults.subdomain) {
                                        pos = 1;
                                    }
                                }(i));

                                if (i === italy.length - 1) {
                                    if (pos === -1) {
                                        $('#searchDomain').attr('data-status', "disabled");
                                        $('.error_domain').html($("body").data('dominio_con_estensione') + ": <b>" + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld + "</b> " + second_tld).show();
                                        status = false;
                                    } else {
                                        exist_subdomain = true;
                                        status = true;
                                    }
                                }
                            }

                            if (status === true) {
                                if ($.FormWhois.defaults.tld !== ".") {
                                    var pos = $.FormWhois.defaults.valid_tlds.indexOf($.FormWhois.defaults.tld);
                                    if (pos === -1) {
                                        $('#searchDomain').attr('data-status', "disabled");
                                        $('.error_domain').html($("body").data('dominio_con_estensione') + ": <b>" + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld + "</b> " + second_tld).show();
                                        status = false;
                                    } else {
                                        status = true;
                                    }
                                    if (exist_subdomain === true && status === true && $.FormWhois.defaults.tld !== ".it") {
                                        $('#searchDomain').attr('data-status', "disabled");
                                        $('.error_domain').html($("body").data("only_it")).show();
                                    }
                                }
                            }

                            if ($.FormWhois.defaults.DomainArray.length > 3) {
                                $('#searchDomain').attr('data-status', "disabled");
                                $('.error_domain').html($("body").data('dominio_con_estensione') + ": <b>" + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld + "</b> " + second_tld).show();
                                status = false;
                            }
                        }
                        else {
                            if (typeof $.FormWhois.defaults.tld !== "undefined") {
                                if ($.FormWhois.defaults.tld !== "." && $.FormWhois.defaults.tld.length > 1) {
                                    var position = -1;
                                    for (var a = 0; a < $.FormWhois.defaults.valid_tlds.length; a++) {
                                        (function (a) {
                                            var tl = $.FormWhois.defaults.valid_tlds[a];
                                            if (tl === $.FormWhois.defaults.tld) {
                                                position = 0;
                                            }

                                        }(a));
                                        if (a === $.FormWhois.defaults.valid_tlds.length - 1) {
                                            if (position === -1) {
                                                status = false;
                                                $('#searchDomain').attr('data-status', "disabled");
                                                $('.error_domain').html(first_tld + ' <b>' + $.FormWhois.defaults.tld + '</b> ' + second_tld).show();
                                            } else {
                                                status = true;
                                                $('.error_domain').html('').hide();
                                                $('#searchDomain').attr('data-status', "enabled");
                                            }
                                        }
                                    }
                                } else {
                                    status = false;
                                    $('.error_domain').html('').hide();
                                    $('#searchDomain').attr('data-status', "enabled");
                                }
                            }

                            if ($.FormWhois.defaults.DomainArray.length > 3) {
                                $('#searchDomain').attr('data-status', "disabled");
                                $('.error_domain').html($("body").data('dominio_inserito') + " " + second_tld).show();
                                status = false;
                            }
                        }
                    } else if ($.FormWhois.defaults.DomainArray.length > 3) {
                        $('#searchDomain').attr('data-status', "disabled");
                        $('.error_domain').html($("body").data('dominio_con_estensione') + ": <b>" + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld + "</b> " + second_tld).show();
                        status = false;
                    } else {
                        $.FormWhois.defaults.domain = $.FormWhois.defaults.domain + $.FormWhois.defaults.tld;
                        $('#form .selection').show();
                    }
                } else {
                    $.FormWhois.defaults.find_domain = false;
                }
            } else {
                $('#fisearchDomainnd').attr('data-status', "disabled");
            }
            if (show_suggest) {
                //FORMWHOIS.DominiSuggeritiInputWhois();
            }

            if (typeof $.FormWhois.defaults.domain !== "undefined") {
                if ($.FormWhois.defaults.domain.length === 0) {
                    $.FormWhois.defaults.find_domain = false;
                }
            }

            $("#domain").val($.FormWhois.defaults.domain);
            FORMWHOIS.hideMessageError();
            return status;
        },
        removeWWW: function () {
            var dom = $.FormWhois.defaults.domain.split(".");
            if (dom.length > 0) {
                if (dom[0] === "www") {
                    $.FormWhois.defaults.domain = $.FormWhois.defaults.domain.replace("www.", "");
                }
            }
        },
        SanitizeDomain: function (dominio, tld_input) {
            try {
                dominio = dominio.toLowerCase().trim();
                dominio = dominio.replace("http://", "");
                dominio = dominio.replace("https://", "");
                var pathArray = dominio.split('/');
                dominio = dominio.replace("www", "");
                dominio = dominio.replace("/", "");
                for (var i = 0; i < pathArray.length; i++) {
                    dominio = pathArray[0];
                    break;
                }
                var reg_point = /../g;
                if (reg_point.test(dominio)) {
                    dominio = dominio.replace("..", ".");
                }

                if ($.FormWhois.defaults.domini_accentati === null) {
                    for (var k = 0; k < $.FormWhois.defaults.accenti.length; k++) {
                        dominio = dominio.replace($.FormWhois.defaults.regex_replace_accentati, ""); //RIMUOVO CARATTERI NON AMMESSI IN UN DOMINIO
                    }
                }

                var dom = dominio.split(".");
                var len = dom.length - 1;
                $.FormWhois.defaults.tld = "." + dom[len];
                $.FormWhois.defaults.sld = dom[0];
                $.FormWhois.defaults.subdomain = "";
                // console.log(dom.length);
                if (len === 0) {

                    $.FormWhois.defaults.tld = tld_input;

                    // dom[1] = tld_input;
                    // len = dom.length - 1;
                }
                if (dom.length === 1) {
                    dom[1] = tld_input;
                }
                if (len === 2) {
                    $.FormWhois.defaults.subdomain = "." + dom[1];
                }
                if (len > 2) {//SE VENGONO AGGIUNTI OLTRE 3 LIVELLI DI DOMINIO
                    $.FormWhois.defaults.sld = "";
                    for (var j = 0; j < len; j++) {
                        $.FormWhois.defaults.sld += dom[j];
                    }
                }
                $.FormWhois.defaults.DomainArray = dom;
                var reg = /www./g;
                if (reg.test(dominio)) { //RIMUOVO IL WWW INSERITO DAL CLIENTE
                    dominio = dominio.replace("www.", "");
                }
                dominio = dominio.replace(" ", "");

                if ($.FormWhois.defaults.domini_accentati === true) {
                    dominio = dominio.replace(/[!_àèéòìù@€\{}#$%^&+*§?"'£()ç,;:\[\]°=\\/|<>]/g, ""); //RIMUOVO CARATTERI NON AMMESSI IN UN DOMINIO
                } else {
                    dominio = dominio.replace(/[!_@#$%€\{}^&+*§?"'£(),ç;:\[\]°=\\/|<>]/g, ""); //RIMUOVO CARATTERI NON AMMESSI IN UN DOMINIO
                }
            } catch (e) {
                // HandleErrors(null, null, null, e, "SanitizeDomain("+dominio+")", "/js-site/pages/domain/domain.js", "212");
                console.log(e);
            }
            if (typeof $.FormWhois.defaults.tld !== "undefined") {
                $.FormWhois.defaults.getDomain = $.FormWhois.defaults.sld + $.FormWhois.defaults.subdomain + $.FormWhois.defaults.tld;
            } else {
                $.FormWhois.defaults.getDomain = $.FormWhois.defaults.sld + $.FormWhois.defaults.subdomain;
            }

            $.FormWhois.defaults.domain = dominio;
            return dominio;
        },
        /**
         *
         * @param char
         * @returns {boolean}
         * @constructor
         */
        SpecialChars: function (char) {
            var ret = false;
            var in_array = "";
            if (typeof char !== "undefined") {
                if (char.length > 1) {
                    for (var i = 0; i < char.length; i++) {
                        in_array = $.FormWhois.defaults.special_character.indexOf(char[i]) > -1;
                        if (in_array) {
                            ret = true;
                            break;
                        }
                    }
                } else {
                    in_array = $.FormWhois.defaults.special_chars.indexOf(char) > -1;
                    if (in_array) {
                        ret = true;
                    }
                }
            }
            return ret;
        },
        /**
         *
         * @param char
         * @returns {boolean}
         * @constructor
         */
        DominiAccentati: function (char) {
            var ret = false;

            if (typeof char !== "undefined") {
                if ($.FormWhois.defaults.domini_accentati === false) {
                    var k = 0;
                    if (char.length > 1) {
                        for (var i = 0; i < char.length; i++) {
                            for (k = 0; k < $.FormWhois.defaults.accent_array.length; k++) {
                                if (char[i] === $.FormWhois.defaults.accent_array[k]) {
                                    ret = true;
                                    break;
                                }
                            }
                        }
                    } else {
                        for (k = 0; k < $.FormWhois.defaults.accent_array.length; k++) {
                            if (char === $.FormWhois.defaults.accent_array[k]) {
                                ret = true;
                                break;
                            }
                        }
                    }
                }
            }
            return ret;
        },
        createArrayCategoriesTld: function () {
            $.FormWhois.defaults.category_tlds = [];
            var geo = [];
            geo["geographic"] = $.FormWhois.defaults.geo_tlds;
            $.FormWhois.defaults.category_tlds.push(geo);

            var generic = [];
            generic["generic"] = ($.FormWhois.defaults.gen_tlds);
            $.FormWhois.defaults.category_tlds.push(generic);

            var new_gtlds = [];
            new_gtlds["new_gtlds"] = ($.FormWhois.defaults.new_gtlds);
            $.FormWhois.defaults.category_tlds.push(new_gtlds);
        },
        /**
         * @param number
         * @param decimals
         * @param dec_point
         * @param thousands_sep
         * @returns {*}
         */
        numberFormat: function (number, decimals, dec_point, thousands_sep) {
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + (Math.round(n * k) / k).toFixed(prec);
                };
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },
        resizeSelect: function () {
            if ($.FormWhois.defaults.adaptive_faq === false) {
                let item = $("#whois-tld-list");
                let w_body = $("body").width();
                let parent = $(item);
                let content = $(parent).find(".left").html();
                let type = $(item).closest(".content-input").find("input").attr("data-form-type");

                if (w_body < 1200) {
                    if (typeof content !== "undefined") {
                        if (content.length > 6) {
                            content = content.substring(0, 6) + " ...";
                        }
                        content = '<div class="left">' + content + '</div>';
                        $(parent).find("div.selected").html(content);
                        if (type !== "reg_domain" && type !== "transfer_domain") {
                            $(item).removeClass("adaptive");
                        }
                    } else {
                    }
                } else {
                    let selected = $(item).find(".item.selected").html();
                    $(parent).find("div.selected").html(selected);
                    (item).addClass("adaptive");
                }
            }
        }
    }
}(document, window, jQuery));

var FORMWHOIS = new $.FormWhois;

