<template>
    <div
            class="modal fade cart-control"
            :id="`hosting_${index}`"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
    >
        <div class="modal-dialog" role="document">
            <span class="gradient-piece-before-modal"></span>
            <div class="modal-content cart-modal">
                <div class="modal-body">
                    <p class="lead">
                        Eliminare l'hosting <b>{{ hosting.name }}</b> dal carrello ?
                    </p>
                    <br />
                    <div class="checkbox" id="checkbox_domain_hosting_remove">
                        <label>
                            <input type="checkbox" v-model="removeDomain" />Eliminare anche il
                            dominio
                            <b>{{ hosting.domain.name }}</b>
                        </label>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-xs-12 col-sm-6 col-md-2 col-md-offset-7">
                            <button
                                    type="button"
                                    class="btn btn-default"
                                    data-dismiss="modal"
                            >
                                Annulla
                            </button>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-3">
                            <a>
                                <form action="/cart/remove-hosting" method="GET">
                                    <input type="hidden" name="_token" :value="token" />
                                    <input type="hidden" name="domain" :value="domain.name" />
                                    <input type="hidden" name="hosting_key" :value="index" />
                                    <input
                                            type="hidden"
                                            name="hosting_pid"
                                            :value="hosting.pid"
                                    />
                                    <input
                                            type="hidden"
                                            name="hosting_domain_name"
                                            :value="hosting.domain.name"
                                    />
                                    <input
                                            type="hidden"
                                            name="domain_remove"
                                            :value="removeDomain"
                                    />
                                    <button
                                            type="submit"
                                            class="btn btn-danger btn-block remove-hosting-domain"
                                    >
                                        Rimuovi
                                    </button>
                                </form>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <span class="gradient-piece-after-modal"></span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RemoveHosting",
        props: ["index", "hosting", "domain", "token"],
        data() {
            return {
                removeDomain: false,
            };
        },
        mounted() {},
    };
</script>
