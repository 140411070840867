<template>
    <div class="empty-trash" id="trash-all">
        <a
                href="javascript:void(0)"
                data-toggle="modal"
                data-target="#trash-all-modal"
        >
            <i class="far fa-trash-alt"></i> &nbsp; <span>Svuota carrello</span>
        </a>

        <div
                class="modal fade cart-control"
                id="trash-all-modal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
        >
            <div class="modal-dialog" role="document">
                <span class="gradient-piece-before-modal"></span>
                <div class="modal-content cart-modal">
                    <div class="modal-body">
                        <p class="lead">Svuotare interamente il carrello ?</p>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-xs-12 col-sm-6 col-md-2 col-md-offset-6">
                                <button
                                        type="button"
                                        class="btn btn-default"
                                        data-dismiss="modal"
                                >
                                    Annulla
                                </button>
                            </div>
                            <div class="col-xs-12 col-sm-6 col-md-4">
                                <a>
                                    <form action="/cart/reset" method="GET">
                                        <input type="hidden" name="_token" :value="token" />
                                        <button
                                                type="submit"
                                                class="btn btn-danger btn-block remove-hosting-domain"
                                        >
                                            Svuota
                                        </button>
                                    </form>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <span class="gradient-piece-after-modal"></span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EmptyCart",
        props: ["token"],
        mounted() {},
    };
</script>
