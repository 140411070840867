<template>
  <div class="form-group discount-form">
    <div class="row">
      <div class="col-md-8">
        <input type="text" class="form-control" id="discount-code" name="discount-code"
          placeholder="inserisci codice sconto" />
        <div class="discount-success">
          <p><i class="fas fa-gift"></i>&nbsp; Codice sconto applicato!</p>
        </div>
        <div class="discount-error">
          <p>Codice sconto non valido</p>
        </div>
      </div>
      <div class="col-md-4">
        <button class="btn-apply-discount" id="btn-apply-discount">
          applica
        </button>
        <div class="discount-success">
          <p>
            <a href="#" id="discount-cancel">cancella</a>
          </p>
        </div>
        <div class="discount-error">
          <p>
            <a href="#" id="discount-retry">riprova</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log("Component mounted.");
  },
};
</script>
