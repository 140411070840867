$(document).ready(function () {
    $('.owl-carousel').owlCarousel({
        loop: true,
        autoplay: true,
        margin: 80,
        nav: true,
        dots: false,
        center: false,
        navText: ["<i class=\"fas fa-chevron-left\"></i>", "<i class=\"fas fa-chevron-right\"></i>"],
        autoplaySpeed: 1000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 3
            },
            1000: {
                items: 4
            },
            1200: {
                items: 6
            }
        }
    });

    $('.owl-carousel .owl-item input.domain-search').on('focus', function (e) {
        $(this).closest('.owl-carousel').trigger('stop.owl.autoplay');
    });
    $('.owl-carousel .owl-item input.domain-search').on('focusout', function (e) {
        $(this).closest('.owl-carousel').trigger('play.owl.autoplay', [1000]);
    });

    $('.carousel-item').click(function (e) {
        e.preventDefault();

        var tld = $(this).data('tld');

        $('html, body').animate({
            scrollTop: ($("input#domain").offset().top + -75)
        }, 500);

        $("input#domain").focus();
        $('select.tlds option[value="' + tld + '"]').prop("selected", true);
    });
});

$('section.alchemy-section h5').html('Siamo qui per prenderci cura del tuo lavoro. Domini.it assicura supporto (tecnico e commerciale)<br />continuo per aiutarti a gestire al meglio il tuo spazio sito internet.');