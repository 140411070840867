/**
 * Created by Giovanni Signorelli
 * Date: 20/6/22
 * Time: 15:10 PM
 */

(function (document, window, $) {
    "use strict";
    $(document).ready(function () {

        $(document).on("click", "nav.navbar .toggleMenu",function(e) {
            e.preventDefault();
            MENU.showHideMenuMobile();
        });

        $(document).on("click", "#menuMobile .asDown",function(e) {
            e.preventDefault();
            let item = $(this);
            MENU.showHideSubMenu(item);
        });

        $(document).on("click", "#menuMobile .asDown li",function(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            let item = $(this);
            MENU.openLinkSubMenu(item);
        });

        $(document).on("click", "#menuMobile .item",function(e) {
            e.preventDefault();
            let item = $(this);
            MENU.openLinkMenu(item);
        });
    });
    $.Menu = function () { };
    $.Menu.defaults = {
        token: null,
        autoHideMenu: true
    };
    $.Menu.prototype = {
        InitEvents: function () {

        },
        /**
         * Method to open link Menu
         * @param item
         */
        openLinkMenu: function(item) {
            if(!$(item).hasClass("asDown"))
            {
                let url = $(item).find("a").attr("href");
                window.location.href = url;
            }
        },
        /**
         * Method to open link submenu
         * @param item
         */
        openLinkSubMenu: function(item) {
            $(item).closest(".asDown").addClass("open");
            let url = $(item).find("a").attr("href");
            window.location.href = url;
        },
        /**
         * Main method to Show/Hide Mobile Menu
         */
        showHideMenuMobile: function () {
            let toggle = $("nav.navbar .toggleMenu");
            let contentMenu = $(".menuTopSection");
            if($(toggle).hasClass("open"))
            {
                MENU.closeMenuMobile(toggle);
            }
            else
            {
                MENU.openMenuMobile(toggle, contentMenu);
            }
        },
        /**
         * Method to Show Mobile Menu
         * @param toggle
         * @param contentMenu
         */
        openMenuMobile: function (toggle, contentMenu) {
            let body = $("body");
            let existsMenu = $(body).find("#menuMobile");
            if($(existsMenu).length === 0) {
                let menuDOM = $(contentMenu).html();
                menuDOM = menuDOM.replace("<nav>", "<nav id='menuMobile'>");
                $(body).append(menuDOM);
            }

            $(body).addClass("openMobileMenu");
            $(toggle).addClass("open");
            $(toggle).find("i").removeClass("fa-bars").addClass("fa-times");

            let chat = $("div[data-id='zsalesiq']");
            $(chat).addClass("hidden");
        },
        /**
         * Method to Hide Mobile Menu
         * @param toggle
         */
        closeMenuMobile: function (toggle) {
            let body = $("body");
            let existsMenu = $(body).find("#menuMobile");
            if($(existsMenu).length > 0) {
                $(existsMenu).remove();
            }

            $(body).removeClass("openMobileMenu");
            $(toggle).removeClass("open");
            $(toggle).find("i").removeClass("fa-times").addClass("fa-bars");

            let chat = $("div[data-id='zsalesiq']");
            $(chat).removeClass("hidden");
        },
        /**
         * Main method to Show/Hide Sub Menu
         * @param item
         */
        showHideSubMenu: function (item) {
            let listItem = $("#menuMobile ul.main li.item");
            if(!$(item).hasClass("open"))
            {
                MENU.showSubMenu(item, listItem);
            } else {
                MENU.hideSubMenu(item);
            }
        },
        /**
         * Method to Show Sub Menu
         * @param item
         * @param listItem
         */
        showSubMenu: function (item, listItem) {

            if($.Menu.defaults.autoHideMenu)
            {
                for(let i = 0; i < $(listItem).length;i++)
                {
                    (function(i) {
                        let itemMenu = $(listItem)[i];
                        MENU.hideSubMenu(itemMenu);

                    }(i));
                    if(i === $(listItem).length - 1)
                    {
                        $(item).addClass("open");
                        $(item).find(".arrowBottom").addClass("fa-chevron-up").removeClass("fa-chevron-down");
                    }
                }
            } else {
                $(item).addClass("open");
                $(item).find(".arrowBottom").addClass("fa-chevron-up").removeClass("fa-chevron-down");
            }
        },
        /**
         * Method to Hide Sub Menu
         * @param item
         */
        hideSubMenu: function (item) {
            $(item).removeClass("open");
            $(item).find(".arrowBottom").addClass("fa-chevron-down").removeClass("fa-chevron-up");
        },
    }
}(document, window, jQuery));

var MENU = new $.Menu;