<template>
    <div>
        <div class="row" v-if="(domains && domains.length) || (hostings && hostings.length)">
            <!-- LEFT -->
            <div class="col-md-8 summary-column" id="left-sidebar">
                <h3 class="summary-title">Riepilogo</h3>
                <!-- H O S T I N G -->
                <div v-if="hostings && hostings.length">
                    <div data-product="hosting" style="margin-bottom: 3rem" v-for="(hosting, indexH) in hostings"
                        :key="`hosting_` + hosting.name + `_${indexH}`"
                        :class="`card shadow rounded hosting ${hosting.name.toLowerCase()}`" :data-hosting-key="indexH"
                        :data-pid="hosting.pid">
                        <span class="title-gray-stripe">
                            <p>
                                <span v-if="hosting.domain">
                                    <span v-if="
                                        hosting.domain.status == `available`
                                    ">Hosting &plus; registrazione
                                        dominio</span>
                                    <span v-else-if="
                                        hosting.domain.choose ==
                                        `just-hosting`
                                    ">Hosting</span>
                                    <span v-else>Hosting &plus; trasferimento
                                        dominio</span>
                                </span>
                                <span v-else>Hosting</span>
                                <a href="javascript:void(0)" class="trash-icon" data-tooltip="tooltip"
                                    data-placement="bottom" title="Rimuovi" data-toggle="modal"
                                    :data-target="`#hosting_${indexH}`">
                                    <i class="far fa-trash-alt"></i>
                                </a>
                            </p>
                        </span>
                        <!-- modal rimozione hosting -->
                        <RemoveHosting :index="indexH" :hosting="hosting" :domain="hosting.domain" :token="token" />
                        <div class="bodyContentDomain">
                            <div :class="`row card-content hosting ${hosting.name.toLowerCase()}`">
                                <div class="col-md-12">
                                    <div class="breakpoint">
                                        <!-- XS breakpoint -->
                                        <div class="row visible-xs visible-sm" style="margin-top: 2rem">
                                            <div class="col-xs-12">
                                                <ul class="main-ul">
                                                    <li class="title">
                                                        <h1>
                                                            <span class="
                                                                            text-uppercase
                                                                        ">{{
                                                                            hosting.name
                                                                        }}</span>
                                                            &nbsp; hosting
                                                        </h1>
                                                    </li>
                                                    <li>
                                                        <span v-if="
                                                            hosting.name.toLowerCase() ==
                                                            `ten`
                                                        ">100</span>
                                                        <span v-else-if="
                                                            hosting.name.toLowerCase() ==
                                                            `five`
                                                        ">50</span>
                                                        <span v-else>20</span>
                                                        GB spazio web
                                                    </li>
                                                    <li>
                                                        <span v-if="
                                                            hosting.name.toLowerCase() ==
                                                            `ten`
                                                        ">Illimitate</span>
                                                        <span v-else-if="
                                                            hosting.name.toLowerCase() ==
                                                            `five`
                                                        ">20</span>
                                                        <span v-else>10</span>
                                                        caselle email
                                                    </li>
                                                    <li>
                                                        <span v-if="
                                                            hosting.name.toLowerCase() ==
                                                            `ten`
                                                        ">Illimitati</span>
                                                        <span v-else-if="
                                                            hosting.name.toLowerCase() ==
                                                            `five`
                                                        ">10</span>
                                                        <span v-else>2</span>
                                                        database
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row visible-xs visible-sm">
                                            <div class="col-xs-12 select">
                                                <div class="form-group paragraph">
                                                    <select class="
                                                                    form-control
                                                                    billingcycle
                                                                " :id="`billing_hosting_${indexH}`" name="billingcycle"
                                                        v-model="
                                                            hosting.billingcycle
                                                        " @change="
    getHostingSubtotal(
        indexH
    )
">
                                                        <option v-for="(
                                                                        price, indexPriceH
                                                                    ) in priceOptions(
                                                                        indexH
                                                                    )" :key="`prezzoH_mobile_${indexPriceH}`" :data-year="
    price.billingcycle
" :data-price="
    price.price
" :data-hosting-key="
    indexH
" :data-pid="hosting.pid" :value="
    price.billingcycle
" :disabled="price <= 0" :class="[
    {
        hidden:
            price <= 0,
    },
]">
                                                            {{ price.billingcycle }}
                                                            {{
                                                                price.billingcycle ==
                                                                1
                                                                ? `anno`
                                                                : `anni`
                                                            }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row visible-xs visible-sm">
                                            <div class="col-xs-12 price-tag">
                                                <p class="price-tag" style="text-align: left">
                                                    &euro;
                                                    <span :id="`price_hosting_${indexH}`">{{
                                                        getHostingSubtotal(
                                                            indexH
                                                        )
                                                    }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="breakpoint">
                                        <table class="
                                                        table
                                                        borderless
                                                        first
                                                        hidden-xs hidden-sm
                                                    ">
                                            <tbody>
                                                <tr>
                                                    <td class="col-md-1 first-td">
                                                        <h2>
                                                            <div class="circle">
                                                                <span class="number" v-if="
                                                                    hosting.name.toLowerCase() ==
                                                                    `ten`
                                                                ">10</span>
                                                                <span class="number" v-else-if="
                                                                    hosting.name.toLowerCase() ==
                                                                    `five`
                                                                ">5</span>
                                                                <span class="number" v-else>1</span>
                                                            </div>
                                                        </h2>
                                                    </td>
                                                    <td class="col-md-6">
                                                        <ul class="main-ul">
                                                            <li class="title">
                                                                <h1>
                                                                    <span class="
                                                                                    text-uppercase
                                                                                ">{{
                                                                                    hosting.name
                                                                                }}</span>&nbsp; hosting
                                                                </h1>
                                                            </li>
                                                            <li>
                                                                <span v-if="
                                                                    hosting.name.toLowerCase() ==
                                                                    `ten`
                                                                ">100</span>
                                                                <span v-else-if="
                                                                    hosting.name.toLowerCase() ==
                                                                    `five`
                                                                ">50</span>
                                                                <span v-else>20</span>
                                                                GB spazio web
                                                            </li>
                                                            <li>
                                                                <span v-if="
                                                                    hosting.name.toLowerCase() ==
                                                                    `ten`
                                                                ">Illimitate</span>
                                                                <span v-else-if="
                                                                    hosting.name.toLowerCase() ==
                                                                    `five`
                                                                ">20</span>
                                                                <span v-else>10</span>
                                                                caselle email
                                                            </li>
                                                            <li>
                                                                <span v-if="
                                                                    hosting.name.toLowerCase() ==
                                                                    `ten`
                                                                ">Illimitati</span>
                                                                <span v-else-if="
                                                                    hosting.name.toLowerCase() ==
                                                                    `five`
                                                                ">10</span>
                                                                <span v-else>2</span>
                                                                database
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td class="col-md-2 select">
                                                        <div class="
                                                                        form-group
                                                                        paragraph
                                                                    ">
                                                            <select class="
                                                                            form-control
                                                                            billingcycle
                                                                        " :id="`billing_hosting_${indexH}`"
                                                                name="billingcycle" v-model="
                                                                    hosting.billingcycle
                                                                " @change="
    getHostingSubtotal(
        indexH
    )
">
                                                                <option v-for="(
                                                                                price,
                                                                                    indexPriceH
                                                                            ) in priceOptions(
                                                                                indexH
                                                                            )" :key="`prezzoH_${indexPriceH}`" :data-year="
    price.billingcycle
" :data-price="price.price" :data-hosting-key="indexH" :data-pid="hosting.pid" :value="price.billingcycle"
                                                                    :disabled="price <= 0" :class="[
                                                                        {
                                                                            hidden:
                                                                                price <=
                                                                                0,
                                                                        },
                                                                    ]">
                                                                    {{
                                                                        price.billingcycle
                                                                    }}
                                                                    {{
                                                                        price.billingcycle ==
                                                                        1
                                                                        ? `anno`
                                                                        : `anni`
                                                                    }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td class="col-md-2 price-tag">
                                                        <p class="price-tag">
                                                            &euro;
                                                            <span :id="`price_hosting_${indexH}`">{{
                                                                getHostingSubtotal(
                                                                    indexH
                                                                )
                                                            }}</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="row card-content " v-if="hosting.domain">
                                <div class="col-md-12">
                                    <div class="
                                                    breakpoint
                                                    visible-xs visible-sm
                                                " style="margin: 2rem 0">
                                        <!-- XS breakpoint -->
                                        <div class="row visible-xs visible-sm">
                                            <div class="col-xs-12">
                                                <ul class="main-ul">
                                                    <li class="title">
                                                        <h2 class="domain">
                                                            {{
                                                                hosting.domain.name
                                                            }}
                                                        </h2>
                                                    </li>
                                                    <!--<li-->
                                                    <!--v-if="-->
                                                    <!--hosting.domain.choose !=-->
                                                    <!--`just-hosting`-->
                                                    <!--"-->
                                                    <!--&gt;-->
                                                    <!--<p-->
                                                    <!--style="-->
                                                    <!--white-space: normal;-->
                                                    <!--"-->
                                                    <!--&gt;-->
                                                    <!--{{-->
                                                    <!--hosting.domain-->
                                                    <!--.status ==-->
                                                    <!--`available`-->
                                                    <!--? `Registrazione`-->
                                                    <!--: `Trasferimento`-->
                                                    <!--}}-->
                                                    <!--dominio con gestione DNS-->
                                                    <!--<span class="green"-->
                                                    <!--&gt;inclusa</span-->
                                                    <!--&gt;-->
                                                    <!--</p>-->
                                                    <!--</li>-->
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row visible-xs visible-sm" v-if="
                                            hosting.domain.choose !=
                                            `just-hosting`
                                        ">
                                            <div class="col-xs-12">
                                                <p class="paragraph text-left" :id="`billing_freedomain_${indexH}`"
                                                    style="text-align: left"></p>
                                            </div>
                                        </div>
                                        <div class="row visible-xs visible-sm" v-if="
                                            hosting.domain.choose !=
                                            `just-hosting`
                                        ">
                                            <div class="col-xs-12">
                                                <p class="gratis-tag" style="text-align: left">
                                                    <span v-if="
                                                        hosting.domain.gratis ==
                                                        true
                                                    "><small>gratis</small></span>
                                                    <span v-else style="color: #408cc9"
                                                        :id="`price_domain_cycle_${indexH}`">
                                                        <small>
                                                            &plus; &euro;
                                                            {{
                                                                hosting.domain
                                                                    .prezzoDominio
                                                            }}
                                                            <!-- {{ hosting.domain.price }} -->
                                                        </small>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="breakpoint">
                                        <table class="
                                                        table
                                                        borderless
                                                        hidden-xs hidden-sm
                                                    ">
                                            <tbody>
                                                <tr>
                                                    <td class="col-md-1 first-td">
                                                        <img src="/assets/globe.svg" v-if="
                                                            hosting.domain
                                                                .status ==
                                                            `available` ||
                                                            hosting.domain
                                                                .choose ==
                                                            `just-hosting`
                                                        " class="
                                                                        globe
                                                                        center-block
                                                                    " width="30" height="30" />
                                                        <img src="/assets/revert.svg" v-else class="
                                                                        revert
                                                                        center-block
                                                                    " width="30" height="30" />
                                                    </td>
                                                    <td class="col-md-6">
                                                        <ul class="main-ul">
                                                            <li class="title">
                                                                <h2 class="domain">
                                                                    {{
                                                                        hosting
                                                                            .domain
                                                                            .name
                                                                    }}
                                                                </h2>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                    <td class="col-md-2 select" v-if="
                                                        hosting.domain.choose !=
                                                        `just-hosting`
                                                    ">
                                                        <!--<p-->
                                                        <!--class="-->
                                                        <!--paragraph-->
                                                        <!--text-center-->
                                                        <!--"-->
                                                        <!--:id="`billing_freedomain_${indexH}`"-->
                                                        <!--&gt;-->
                                                        <!--{{-->
                                                        <!--hosting.billingcycle-->
                                                        <!--}}-->
                                                        <!--{{-->
                                                        <!--hosting.billingcycle ==-->
                                                        <!--1-->
                                                        <!--? `anno`-->
                                                        <!--: `anni`-->
                                                        <!--}}-->
                                                        <!--</p>-->
                                                    </td>
                                                    <td class="col-md-2 price-tag" v-if="
                                                        hosting.domain.choose !=
                                                        `just-hosting`
                                                    ">
                                                        <div class="gratis-tag">
                                                            <span v-if="
                                                                hosting.domain
                                                                    .gratis ==
                                                                true
                                                            "><small>gratis</small></span>
                                                            <span v-else style="
                                                                            color: #408cc9;
                                                                        " :id="`price_domain_cycle_${indexH}`">
                                                                <small>
                                                                    &plus; &euro;
                                                                    {{
                                                                        hosting
                                                                            .domain
                                                                            .prezzoDominio
                                                                    }}
                                                                    <!-- {{ hosting.domain.price }} -->
                                                                </small>
                                                            </span>
                                                            <div v-if="
                                                                hosting.domain.choose !=
                                                                `just-hosting`
                                                            ">
                                                                <div style="text-align: right; margin-top: -6px;" class="
                                                                                paragraph
                                                                            " :id="`billing_freedomain_${indexH}`">/
                                                                    {{
                                                                        hosting.billingcycle
                                                                    }}
                                                                    {{
                                                                        hosting.billingcycle ==
                                                                        1
                                                                        ? `anno`
                                                                        : `anni`
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="col-md-4" v-if="
                                                        hosting.domain.choose ==
                                                        `just-hosting`
                                                    ">
                                                        &nbsp;
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="row">
                                            <div class="col-md-1"></div>
                                            <div class="col-md-11">
                                                <ul class="main-ul"
                                                    style="width: 100%; margin-left: 10px;margin-top: -20px">
                                                    <li v-if="
                                                        hosting.domain
                                                            .choose !=
                                                        `just-hosting`
                                                    ">
                                                        <p style="
                                                                                white-space: normal;
                                                                            ">
                                                            {{
                                                                hosting
                                                                    .domain
                                                                    .status ==
                                                                `available`
                                                                ? `Registrazione`
                                                                : `Trasferimento`
                                                            }}
                                                            dominio con
                                                            gestione DNS
                                                            <span style="display: inline-block" class="
                                                                                    green
                                                                                ">inclusa</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <!-- <div class="col-md-2">
                                                <p v-if="hosting.domain.renewPrices" class="text-right">AL RINNOVO</p>
                                                <p v-if="hosting.domain.renewPrices" class="price-tag-renew">
                                                    &euro; {{ hosting.domain.renewPrices[hosting.billingcycle]
                                                    }} + IVA
                                                </p>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row carousel hostingDomainContent" v-if="hosting.addons && hosting.addons.length">
                                <div class="col-xs-12 col-md-10 col-md-offset-1">
                                    <span class="add-ons">Opzioni aggiuntive Hosting</span>

                                    <div class="row">
                                        <!-- addon relativi al solo hosting -->
                                        <label v-for="(
                                                        addon, indexAddon
                                                    ) in hosting.addons" :key="addon.addon_id + `_${indexH}`" :for="
                                                        `hosting_` +
                                                        indexH +
                                                        '_' +
                                                        addon.name
                                                            .toLowerCase()
                                                            .replace('/\s+/', '_')
                                                    " class="addons  col-lg-12 col-md-12 col-sm-12" :class="[
    {
        optionSelected:
            addon.checked
    },
]" @click="getHostingSubtotal(indexH)">
                                            <div class="contentOptions hostingOpt" :data-key="indexAddon"
                                                :data-hosting-key="indexH" :data-addon-id="addon.addon_id">
                                                <figure>
                                                    <i v-if="addon.addon_id == '1'" class="fas fa-box-heart iconAddon"></i>
                                                    <i v-if="addon.addon_id == '2'"
                                                        class="fas fa-cloud-download-alt iconAddon"></i>
                                                </figure>
                                                <input type="checkbox" :id="
                                                    `hosting_` +
                                                    indexH +
                                                    '_' +
                                                    addon.name
                                                        .toLowerCase()
                                                        .replace('/\s+/', '_')
                                                " :value="addon.annually" :data-hosting-key="indexH"
                                                    :data-key="indexAddon" class="option-check"
                                                    :id-sec="`addon_value_${indexAddon}`" :name="
                                                        addon.name
                                                            .toLowerCase()
                                                            .replace('/\s+/', '_')
                                                    " :data-pid="addon.pid" :data-addon="
    addon.name
        .toLowerCase()
        .replace('/\s+/', '_')
" v-model="addon.checked" />
                                                <label>{{ addon.name }}</label>
                                                <p>

                                                    {{ addon.description }}

                                                </p>
                                                <span class="carousel-price">
                                                    +
                                                    &euro;
                                                    <span v-if="hosting.billingcycle == 1">{{ addon.annually }}</span>
                                                    <span v-else-if="hosting.billingcycle == 2">{{
                                                        addon.biennially
                                                    }}</span>
                                                    <span v-else>{{ addon.triennially }}</span>
                                                    <span class="
                                                                        yearAddon
                                                                    ">/
                                                        {{
                                                            hosting.billingcycle
                                                        }}
                                                        {{
                                                            hosting.billingcycle ==
                                                            1
                                                            ? `anno`
                                                            : `anni`
                                                        }}
                                                    </span>
                                                    <span class="carousel-iva"></span>
                                                </span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row carousel hostingDomainContent" v-if="
                                hosting.domain.choose !=
                                `just-hosting` &&
                                hosting.domain.addons &&
                                Object.entries(
                                    hosting.domain.addons
                                ).length
                            ">
                                <div class="col-xs-12 col-md-10 col-md-offset-1">
                                    <!-- addon relativi al dominio associato all'hosting -->
                                    <br />
                                    <span class="add-ons">Opzioni aggiuntive dominio
                                    </span>
                                    <div class="row">
                                        <!-- id_protection -->
                                        <label v-if="
                                            hosting.domain.addons
                                                .id_protection &&
                                            hosting.domain.addons
                                                .id_protection.purchasable
                                        " :for="`hosting_${indexH}_id_protection`"
                                            class="addons col-lg-6 col-md-6 col-sm-12" :class="[
                                                {
                                                    multiOptions:
                                                        hosting.domain.addons.id_protection &&
                                                        hosting.domain.addons.id_protection.purchasable &&
                                                        hosting.domain.addons.email_forwarding &&
                                                        hosting.domain.addons.email_forwarding.purchasable
                                                },
                                                {
                                                    optionSelected:
                                                        hosting.domain.addons.id_protection.checked,
                                                },
                                            ]
                                            " @click="getHostingSubtotal(indexH)">
                                            <div class="contentOptions">
                                                <input type="checkbox" :id="`hosting_${indexH}_id_protection`"
                                                    name="id_protection" v-model="
                                                        hosting.domain.addons
                                                            .id_protection
                                                            .checked
                                                    " />
                                                <label>Privacy dominio</label>
                                                <p>
                                                    Proteggi e nascondi i tuoi
                                                    dati personali dal sistema
                                                    Whois.
                                                </p>
                                                <span class="carousel-price">&euro;{{
                                                    hosting.domain.addons
                                                        .id_protection
                                                        .price
                                                }}<span class="carousel-iva"></span></span>
                                            </div>
                                        </label>

                                        <!-- email_forwarding -->
                                        <label v-if="
                                            hosting.domain.addons
                                                .email_forwarding &&
                                            hosting.domain.addons
                                                .email_forwarding
                                                .purchasable
                                        " :for="`hosting_${indexH}_email_forwarding`"
                                            class="addons col-lg-6 col-md-6 col-sm-12" :class="[
                                                {
                                                    multiOptions:
                                                        hosting.domain.addons.id_protection &&
                                                        hosting.domain.addons.id_protection.purchasable &&
                                                        hosting.domain.addons.email_forwarding &&
                                                        hosting.domain.addons.email_forwarding.purchasable
                                                },
                                                {
                                                    optionSelected:
                                                        hosting.domain.addons.id_protection.checked,
                                                },
                                            ]
                                            " @click="getHostingSubtotal(indexH)">
                                            <div class="contentOptions">
                                                <input type="checkbox" :id="`hosting_${indexH}_email_forwarding`" :value="
                                                    hosting.domain.addons
                                                        .email_forwarding
                                                        .price
                                                " name="email_forwarding" v-model="
    hosting.domain.addons
        .email_forwarding
        .checked
" />
                                                <label>Inoltro mail</label>
                                                <p>
                                                    Gestisci le tue caselle
                                                    email da un singolo account
                                                    di posta elettronica.
                                                </p>
                                                <span class="carousel-price">&euro;
                                                    {{
                                                        hosting.domain.addons
                                                            .email_forwarding
                                                            .price
                                                    }}
                                                    <span class="carousel-iva"></span></span>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row hostingDomainContent">
                                <div class="col-xs-12 col-md-10 col-md-offset-1">
                                    <div class="eppcode" v-if="
                                        hosting.domain.eppcode &&
                                        Object.entries(hosting.domain.eppcode)
                                            .length &&
                                        hosting.domain.choose !=
                                        `just-hosting` &&
                                        hosting.domain.status == `unavailable`
                                    ">
                                        <div class="form-group has-feedback" :class="[
                                            {
                                                'has-error':
                                                    $v.hostings.$each[indexH]
                                                        .domain.eppcode.value
                                                        .$invalid,
                                            },
                                            {
                                                'has-success':
                                                    !$v.hostings.$each[indexH]
                                                        .domain.eppcode.value
                                                        .$invalid,
                                            },
                                        ]">
                                            <label class="eppcode control-label">Codice di migrazione
                                                EPP/AuthInfo</label>
                                            <span class="info">
                                                Per trasferire un dominio
                                                bisogna richiedere al vecchio
                                                provider il codice di
                                                autorizzazione (detto anche EPP
                                                Code oppure AuthInfo). E'
                                                possibile indicare il codice EPP
                                                in un secondo momento segliendo
                                                di non effetturare subito il
                                                trasferimento.
                                            </span>
                                            <input class="form-control shadow-sm" :class="[
                                                {
                                                    'is-invalid':
                                                        invalidCart &&
                                                        $v.hostings.$each[
                                                            indexH
                                                        ].domain.eppcode.value
                                                            .$invalid,
                                                },
                                                {
                                                    'is-valid':
                                                        invalidCart &&
                                                        !$v.hostings.$each[
                                                            indexH
                                                        ].domain.eppcode.value
                                                            .$invalid,
                                                },
                                            ]" :id="`hosting_${indexH}_eppcode`" type="text" name="eppcode"
                                                :minlength="8" :maxlength="
                                                    hosting.domain.tld == `eu`
                                                        ? 19
                                                        : null
                                                " :disabled="
    hosting.domain.eppcode
        .checked === true
" autocomplete="off" :autofocus="
    invalidCart &&
    $v.hostings.$each[indexH].domain
        .eppcode.value.$invalid
" :data-tld="hosting.domain.tld" v-model.trim="
    hosting.domain.eppcode.value
" placeholder="Inserisci codice di migrazione..." />
                                            <div class="message-box">
                                                <span class="message-text-bottom"></span>
                                            </div>
                                            <label class="eppcode_later" :for="`hosting_${indexH}_eppcode_later`" @click="
                                                hosting.domain.eppcode.value =
                                                null
                                            ">
                                                <input type="checkbox" :id="`hosting_${indexH}_eppcode_later`"
                                                    name="eppcode_later" v-model="
                                                        hosting.domain.eppcode
                                                            .checked
                                                    " />
                                                &nbsp;Fornirò il codice in un
                                                secondo momento
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- D O M I N I -->
                <div v-if="domains && domains.length">
                    <div data-product="domain" style="margin-bottom: 3rem" v-for="(domain, index) in domains"
                        :key="`domain_` + domain.name" class="card shadow rounded domain" :data-array-key="index"
                        :data-domain="domain.name">
                        <span class="title-gray-stripe">
                            <p>
                                {{
                                    domain.whois == `unavailable`
                                    ? `Trasferimento dominio`
                                    : `Registrazione dominio`
                                }}
                                <a href="javascript:void(0)" class="trash-icon" data-tooltip="tooltip"
                                    data-placement="bottom" title="Rimuovi" data-toggle="modal"
                                    :data-target="`#domain_${index}`">
                                    <i class="far fa-trash-alt"></i>
                                </a>
                            </p>
                        </span>

                        <!-- modal rimozione dominio -->
                        <RemoveDomain :index="index" :domain="domain" :token="token" />
                        <div class="bodyContentDomain">
                            <div class="row card-content domain">
                                <div class="col-md-12">
                                    <div class="breakpoint">
                                        <div class="row visible-xs visible-sm" style="margin-top: 2rem">
                                            <div class="col-xs-12">
                                                <ul class="main-ul">
                                                    <li class="title">
                                                        <h2 class="reg-domain">
                                                            {{ domain.name }}
                                                        </h2>
                                                    </li>
                                                    <li>
                                                        <p style="
                                                                        white-space: normal;
                                                                    ">
                                                            {{
                                                                domain.whois ==
                                                                `unavailable`
                                                                ? `Trasferimento dominio`
                                                                : `Registrazione dominio`
                                                            }}
                                                            con gestione DNS
                                                            <span class="green">inclusa</span>
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="row visible-xs visible-sm">
                                            <div class="col-xs-12 select">
                                                <div class="form-group paragraph">
                                                    <select v-if="
                                                        domain.prices &&
                                                        Object.entries(
                                                            domain.prices
                                                        ).length
                                                    " class="
                                                                    form-control
                                                                    billingcycle
                                                                " :id="
                                                                    `select_` +
                                                                    domain.sld +
                                                                    '_' +
                                                                    domain.tld.replace(
                                                                        '.',
                                                                        '_'
                                                                    )
                                                                " name="billingcycle" v-model="
    domain.billingcycle
" @change="
    getDomainSubtotal(
        index
    )
">
                                                        <option v-for="(
                                                                        price,
                                                                            indexPrice
                                                                    ) in domain.prices" :key="
                                                                        domain.tld +
                                                                        `_mobile_` +
                                                                        price +
                                                                        `_` +
                                                                        indexPrice
                                                                    " :data-year="
    indexPrice
" :data-price="price" :data-sld-tld="
    domain.sld +
    '_' +
    domain.tld.replace(
        '.',
        '_'
    )
" :selected="
    domain.billingcycle ==
    indexPrice
" :value="indexPrice" :disabled="
    price <= 0
" :class="[
    {
        hidden:
            price <=
            0,
    },
]">
                                                            {{ indexPrice }}
                                                            {{
                                                                indexPrice == 1
                                                                ? `anno`
                                                                : `anni`
                                                            }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row visible-xs visible-sm">
                                            <div class="col-xs-12 price-tag">
                                                <p class="price-tag" style="text-align: left">
                                                    &euro;
                                                    <span :id="
                                                        `price_` +
                                                        domain.sld +
                                                        '_' +
                                                        domain.tld.replace(
                                                            '.',
                                                            '_'
                                                        )
                                                    ">
                                                        {{
                                                            getDomainSubtotal(
                                                                index
                                                            )
                                                        }}
                                                    </span>
                                                </p>
                                                <br>
                                                <p v-if="domain.renewPrices" class="text-left">AL RINNOVO</p>
                                                <p v-if="domain.renewPrices" class="price-tag-renew-mobile">
                                                    &euro; {{ domain.renewPrices[domain.billingcycle]
                                                    }} + IVA
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="breakpoint">
                                        <table class="
                                                        table
                                                        borderless
                                                        first
                                                        hidden-xs hidden-sm
                                                    ">
                                            <tbody>
                                                <tr>
                                                    <td class="
                                                                    col-md-1
                                                                    first-td
                                                                ">
                                                        <img v-if="
                                                            domain.whois ==
                                                            `unavailable`
                                                        " src="/assets/revert.svg" class="
                                                                        revert-bg
                                                                        center-block
                                                                    " width="50" height="50" />
                                                        <img v-else src="/assets/globe.svg" class="
                                                                        globe-bg
                                                                        center-block
                                                                    " width="50" height="50" />
                                                    </td>
                                                    <td class="col-md-7">
                                                        <ul class="main-ul">
                                                            <li class="title">
                                                                <h2 class="
                                                                                reg-domain
                                                                            ">
                                                                    {{
                                                                        domain.name
                                                                    }}
                                                                </h2>
                                                            </li>
                                                            <li>
                                                                <p style="
                                                                                white-space: normal;
                                                                            ">
                                                                    {{
                                                                        domain.whois ==
                                                                        `unavailable`
                                                                        ? `Trasferimento dominio`
                                                                        : `Registrazione dominio`
                                                                    }}
                                                                    con gestione
                                                                    DNS
                                                                    <span class="
                                                                                    green
                                                                                ">inclusa</span>
                                                                </p>
                                                            </li>
                                                        </ul>
                                                        <!-- <ul class="free-addons">
                                                  <li v-if="domain.addons.dns_management.price == 0">
                                                    Gestione DNS
                                                  </li>
                                                </ul> -->
                                                    </td>
                                                    <td class="col-md-2 select">
                                                        <div class="
                                                                        form-group
                                                                        paragraph
                                                                    ">
                                                            <select v-if="
                                                                domain.prices &&
                                                                Object.entries(
                                                                    domain.prices
                                                                ).length
                                                            " class="
                                                                            form-control
                                                                            billingcycle
                                                                        " :id="
                                                                            `select_` +
                                                                            domain.sld +
                                                                            '_' +
                                                                            domain.tld.replace(
                                                                                '.',
                                                                                '_'
                                                                            )
                                                                        " name="billingcycle" v-model="
    domain.billingcycle
" @change="
    getDomainSubtotal(
        index
    )
">
                                                                <option v-for="(
                                                                                price,
                                                                                    indexPrice
                                                                            ) in domain.prices" :key="
                                                                                domain.tld +
                                                                                `_` +
                                                                                price +
                                                                                `_` +
                                                                                indexPrice
                                                                            " :data-year="
    indexPrice
" :data-price="
    price
" :data-sld-tld="
    domain.sld +
    '_' +
    domain.tld.replace(
        '.',
        '_'
    )
" :disabled="
    price <=
    0
" :class="[
    {
        hidden:
            price <=
            0,
    },
]" :selected="
    domain.billingcycle ==
    indexPrice
" :value="
    indexPrice
">
                                                                    {{
                                                                        indexPrice
                                                                    }}
                                                                    {{
                                                                        indexPrice ==
                                                                        1
                                                                        ? `anno`
                                                                        : `anni`
                                                                    }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    <td class="
                                                                    col-md-2
                                                                    price-tag
                                                                ">
                                                        <p class="price-tag">
                                                            &euro;
                                                            <span :id="
                                                                `price_` +
                                                                domain.sld +
                                                                '_' +
                                                                domain.tld.replace(
                                                                    '.',
                                                                    '_'
                                                                )
                                                            ">
                                                                {{
                                                                    getDomainSubtotal(
                                                                        index
                                                                    )
                                                                }}
                                                            </span>
                                                        </p>
                                                        <br />
                                                        <p v-if="domain.renewPrices" class="price-tag-renew">
                                                            &euro; {{ domain.renewPrices[domain.billingcycle]
                                                            }} + IVA
                                                        </p>
                                                        <p v-if="domain.renewPrices" class="text-right">AL RINNOVO</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row carousel">
                                <div class="col-xs-12 col-md-10 col-md-offset-1">
                                    <div v-if="
                                        domain.addons.email_forwarding
                                            .purchasable ||
                                        domain.addons.id_protection
                                            .purchasable
                                    ">
                                        <span class="add-ons">Opzioni aggiuntive:</span>
                                    </div>

                                    <div class="row">
                                        <!-- id_protection -->
                                        <label v-if="
                                            domain.addons.id_protection &&
                                            domain.addons.id_protection
                                                .purchasable
                                        " :for="
    `domain_` +
    index +
    `_id_protection`
" class="
                                                        addons
                                                        col-lg-6 col-md-6 col-sm-12
                                                    " :class="[
                                                        {
                                                            multiOptions:
                                                                domain.addons
                                                                    .email_forwarding &&
                                                                domain.addons
                                                                    .email_forwarding
                                                                    .purchasable &&
                                                                domain.addons
                                                                    .id_protection &&
                                                                domain.addons
                                                                    .id_protection
                                                                    .purchasable,
                                                        },
                                                        {
                                                            optionSelected:
                                                                domain.addons
                                                                    .id_protection
                                                                    .checked,
                                                        },
                                                    ]" @click="getDomainSubtotal(index)">
                                            <div class="contentOptions">
                                                <input type="checkbox" :id="
                                                    `domain_` +
                                                    index +
                                                    `_id_protection`
                                                " :data-sld-tld="
    domain.sld +
    '_' +
    domain.tld.replace(
        '.',
        '_'
    )
" name="id_protection" v-model="
    domain.addons
        .id_protection
        .checked
" />
                                                <label>Privacy dominio</label>
                                                <p>
                                                    Proteggi e nascondi i tuoi
                                                    dati personali dal sistema
                                                    Whois.
                                                </p>
                                                <span class="carousel-price">&euro;
                                                    {{
                                                        domain.addons
                                                            .id_protection.price
                                                    }}
                                                    <span class="carousel-iva"></span></span>
                                            </div>
                                        </label>
                                        <!-- email_forwarding -->
                                        <label v-if="
                                            domain.addons
                                                .email_forwarding &&
                                            domain.addons.email_forwarding
                                                .purchasable
                                        " :for="
    `domain_` +
    index +
    `_email_forwarding`
" class="
                                                        addons
                                                        col-lg-6 col-md-6 col-sm-12
                                                    " @click="getDomainSubtotal(index)" :class="[
                                                        {
                                                            multiOptions:
                                                                domain.addons
                                                                    .email_forwarding &&
                                                                domain.addons
                                                                    .email_forwarding
                                                                    .purchasable &&
                                                                domain.addons
                                                                    .id_protection &&
                                                                domain.addons
                                                                    .id_protection
                                                                    .purchasable,
                                                        },
                                                        {
                                                            optionSelected:
                                                                domain.addons
                                                                    .email_forwarding
                                                                    .checked,
                                                        },
                                                    ]">
                                            <div class="contentOptions">
                                                <input type="checkbox" :id="
                                                    `domain_` +
                                                    index +
                                                    `_email_forwarding`
                                                " :data-sld-tld="
    domain.sld +
    '_' +
    domain.tld.replace(
        '.',
        '_'
    )
" :value="
    domain.addons
        .email_forwarding
        .price
" name="email_forwarding" :checked="
    domain.addons
        .email_forwarding
        .checked
" v-model="
    domain.addons
        .email_forwarding
        .checked
" />
                                                <label>Inoltro mail</label>
                                                <p>
                                                    Gestisci le tue caselle
                                                    email da un singolo account
                                                    di posta elettronica.
                                                </p>
                                                <span class="carousel-price">&euro;
                                                    {{
                                                        domain.addons
                                                            .email_forwarding
                                                            .price
                                                    }}
                                                    <span class="carousel-iva"></span></span>
                                            </div>
                                        </label>
                                    </div>
                                    <!-- epp_code -->
                                    <div class="eppcode" v-if="domain.whois == `unavailable`">
                                        <div class="form-group has-feedback" :class="[
                                            {
                                                'has-error':
                                                    $v.domains.$each[index]
                                                        .addons.epp_code
                                                        .value.$invalid,
                                            },
                                            {
                                                'has-success':
                                                    !$v.domains.$each[index]
                                                        .addons.epp_code
                                                        .value.$invalid,
                                            },
                                        ]">
                                            <label :for="
                                                `domain_` +
                                                index +
                                                `_eppcode`
                                            " class="eppcode control-label">Codice di migrazione
                                                EPP/AuthInfo</label>
                                            <span class="info">
                                                Per trasferire un dominio
                                                bisogna richiedere al vecchio
                                                provider il codice di
                                                autorizzazione (detto anche EPP
                                                Code oppure AuthInfo). E'
                                                possibile indicare il codice EPP
                                                in un secondo momento segliendo
                                                di non effetturare subito il
                                                trasferimento.
                                            </span>
                                            <input class="form-control shadow-sm" :class="[
                                                {
                                                    'is-invalid':
                                                        invalidCart &&
                                                        $v.domains.$each[
                                                            index
                                                        ].addons.epp_code
                                                            .value.$invalid,
                                                },
                                                {
                                                    'is-valid':
                                                        invalidCart &&
                                                        !$v.domains.$each[
                                                            index
                                                        ].addons.epp_code
                                                            .value.$invalid,
                                                },
                                            ]" :id="
    `domain_` +
    index +
    `_eppcode`
" type="text" name="eppcode" :minlength="8" :maxlength="
    domain.tld == `eu`
        ? 19
        : null
" :disabled="
    domain.addons.epp_code
        .checked === true
" autocomplete="off" :autofocus="
    invalidCart &&
    $v.domains.$each[index]
        .addons.epp_code.value
        .$invalid
" :data-tld="domain.tld" v-model.trim="
    domain.addons.epp_code.value
" placeholder="Inserisci codice di migrazione..." />
                                            <div class="message-box">
                                                <span class="message-text-bottom" :id="`epp_code_` + index"></span>
                                            </div>
                                            <label :for="
                                                `domain_` +
                                                index +
                                                `_eppcode_later`
                                            " class="eppcode_later" @click="
    domain.addons.epp_code.value =
    null
">
                                                <input type="checkbox" :id="
                                                    `domain_` +
                                                    index +
                                                    `_eppcode_later`
                                                " name="eppcode_later" v-model="
    domain.addons.epp_code
        .checked
" />
                                                &nbsp;Fornirò il codice in un
                                                secondo momento
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- CALL TO ACTION ADD HOSTING -->
                        <div class="addHostingCta">
                            <div class="row">
                                <div class="
                                                col-md-11
                                                col-md-offset-1
                                                text-center
                                                associate-hosting-cta
                                            ">
                                    <p>
                                        Devi realizzare un sito web?
                                        <span v-if="domain.tld == 'it'" class="freeDomain">Aggiungi un hosting e il
                                            dominio sarà
                                            <strong>GRATIS</strong></span>
                                        <span v-if="domain.tld != 'it'" class="descAddDomain">Aggiungi un piano hosting
                                            al
                                            tuo dominio</span>
                                        <span class="visible-xs" style="margin-top: 1rem"></span>

                                        <button class="
                                                        btn
                                                        associate-hosting-btn
                                                        hidden-xs
                                                    " data-toggle="modal"
                                            :data-target="`#domain_modal_associate_hosting_${index}`"
                                            style="margin-left: 1rem">
                                            Aggiungi hosting
                                        </button>

                                        <button class="
                                                        btn
                                                        associate-hosting-btn
                                                        visible-xs
                                                        text-center
                                                        center-block
                                                    " data-toggle="modal"
                                            :data-target="`#domain_modal_associate_hosting_mobile_${index}`"
                                            onclick="setTimeout(() => { document.getElementById(`domain_modal_associate_hosting_mobile_content_${ index }`).scrollIntoView({behavior: 'smooth'}); }, 500)">
                                            Aggiungi hosting
                                        </button>
                                    </p>
                                    <span class="hidden-xs">
                                        <AssociateHosting :index="index" :productGroups="productGroups" :prodotti="prodotti"
                                            :domain="domain" :itemsPerRow="itemsPerRow" :token="token" />
                                    </span>
                                    <div class="visible-xs">
                                        <AssociateHostingMobile :index="index" :productGroups="productGroups"
                                            :prodotti="prodotti" :domain="domain" :itemsPerRow="itemsPerRow"
                                            :token="token" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <EmptyCart v-if="
                    (domains && domains.length) ||
                    (hostings && hostings.length)
                " :token="token" />
            </div>
            <!-- RIGHT -->
            <div class="col-md-4 sidebar-column sticky" id="right-sidebar">
                <h3 class="cart-title">Carrello</h3>
                <div class="sidebar shadow rounded">
                    <!-- riepilogo hosting -->
                    <div v-if="hostings && hostings.length">
                        <table class="table borderless hosting">
                            <thead>
                                <tr>
                                    <th class="service-category col-md-8">
                                        hosting
                                        <small>({{ hostings.length }})</small>
                                    </th>
                                    <th class="
                                                    service-price
                                                    hosting-subtotal
                                                    col-md-4
                                                " id="hosting-subtotal">
                                        <span>
                                            &euro; {{ getTotalHostings() }}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :id="indexHosting" v-for="(hosting, indexHosting) in hostings"
                                    :key="indexHosting + `_recap`">
                                    <td>
                                        <span>{{ hosting.name }}</span> hosting
                                        <span :id="
                                            `hosting_billing_` +
                                            indexHosting
                                        "></span>
                                        <small v-if="hosting.domain">
                                            <br />
                                            &plus;[<u>{{
                                                hosting.domain.name
                                            }}</u>]
                                        </small>
                                        <small v-else></small>
                                    </td>
                                    <td class="text-right font-weight-bold">
                                        &euro;
                                        {{ getHostingSubtotal(indexHosting) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                    </div>
                    <!-- riepilogo domini -->
                    <div v-if="domains && domains.length">
                        <table class="table borderless domains">
                            <thead>
                                <tr>
                                    <th class="service-category col-md-8">
                                        domini
                                        <small>({{ domains.length }})</small>
                                    </th>
                                    <th class="
                                                    service-price
                                                    domains-subtotal
                                                    col-md-4
                                                " id="domains-subtotal">
                                        <span>
                                            &euro; {{ getTotalDomains() }}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :id="domain.sld + '_' + domain.tld" v-for="(domain, indexDomain) in domains"
                                    :key="indexDomain + `_recap`">
                                    <td>
                                        <span>{{ domain.name }}</span>
                                        <span :id="
                                            `domain_billing_` +
                                            domain.sld +
                                            '_' +
                                            domain.tld
                                        "></span>
                                    </td>
                                    <td class="text-right font-weight-bold">
                                        &euro;
                                        {{ getDomainSubtotal(indexDomain) }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                    </div>
                    <!-- qui va inserito eventuale blocco per gestione coupon -->
                    <!-- sconto, subtotale, iva -->
                    <div v-if="coupon" class="row calculateRow">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <span>Sconto</span>
                        </div>
                        <div class="
                                        col-lg-6 col-md-6 col-sm-6 col-xs-6
                                        rightContent
                                    ">
                            &euro; -{{ discount.toFixed(2) }}
                        </div>
                    </div>
                    <div class="row calculateRow">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <span>Subtotale</span>
                        </div>
                        <div class="
                                        col-lg-6 col-md-6 col-sm-6 col-xs-6
                                        rightContent
                                    ">
                            &euro; {{ getSubTotal() }}
                        </div>
                    </div>
                    <div class="row calculateRow">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <span>iva {{ iva }}%</span>
                        </div>
                        <div class="
                                        col-lg-6 col-md-6 col-sm-6 col-xs-6
                                        rightContent
                                    ">
                            &euro; {{ getTax() }}
                        </div>
                    </div>
                    <div class="row calculateRow">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <span class="total">Totale</span>
                        </div>
                        <div class="
                                        col-lg-6 col-md-6 col-sm-6 col-xs-6
                                        rightContent
                                        totalContent
                                    ">
                            &euro; {{ getTotal() }}
                        </div>
                    </div>
                    <!-- totale generale carrello -->
                    <div class="row hidden">
                        <div class="col-md-12 text-center total-order">
                            <a class="btn-order-confirm btn-block center-block" href="javascript:void(0)" id="buy_now"
                                @click="saveCart()">
                                <i class="fas fa-shopping-cart"></i>
                                <span class="btn-text">Acquista ora</span>
                            </a>
                        </div>
                    </div>
                </div>
                <p class="add-product">
                    <a href="/">Aggiungi un altro prodotto</a>
                </p>
            </div>
        </div>
        <!-- EMPTY CART -->
        <div class="row empty-cart" v-else>
            <div class="col-md-12 text-center">
                <img src="/new/logo-sad-dark.svg" class="center-block img-responsive" />
                <p>il tuo carrello è vuoto</p>
                <a href="/" class="btn"> Torna alla home page </a>
            </div>
        </div>
    </div>
</template>

<script>
import EmptyCart from "./partials/EmptyCart.vue";
import RemoveDomain from "./partials/RemoveDomain.vue";
import RemoveHosting from "./partials/RemoveHosting.vue";
import AssociateHosting from "./partials/AssociateHosting.vue";
import AssociateHostingMobile from "./partials/AssociateHostingMobile.vue";

import {
    required,
    requiredIf,
    minLength,
    maxLength,
    alpha,
    alphaNum,
    numeric,
    integer,
    decimal,
} from "vuelidate/lib/validators";

export default {
    name: "CartIndex",
    components: {
        EmptyCart,
        RemoveDomain,
        RemoveHosting,
        AssociateHosting,
        AssociateHostingMobile,
    },
    props: ["data", "iva", "products", "token"],
    data() {
        return {
            itemsPerRow: 3,
            domains: [],
            hostings: [],
            prodotti: [],
            totalDomains: 0.0,
            totalHostings: 0.0,
            total: 0.0,
            subtotal: 0.0,
            tax: 0.0,
            discount: 0.0,
            coupon: null,
            invalidCart: false,
        };
    },
    validations: {
        domains: {
            $each: {
                addons: {
                    epp_code: {
                        value: {
                            required: requiredIf(function (obj) {
                                return obj.required && !obj.checked ? true : false;
                            }),
                            // alphaNum,
                            minLength: minLength(8),
                            maxLength: maxLength(32),
                        },
                    },
                },
            },
        },
        hostings: {
            $each: {
                domain: {
                    eppcode: {
                        value: {
                            required: requiredIf(function (obj) {
                                return obj.required && !obj.checked ? true : false;
                            }),
                            // alphaNum,
                            minLength: minLength(8),
                            maxLength: maxLength(32),
                        },
                    },
                },
            },
        },
    },
    computed: {
        productGroups() {
            return Array.from(
                Array(Math.ceil(this.prodotti.length / this.itemsPerRow)).keys()
            );
        },
    },
    methods: {
        scrollToTop(validationMessage, stepMessage, alertType) {
            if (validationMessage) {
                $.notify(
                    {
                        message: stepMessage,
                    },
                    {
                        type: alertType,
                        allow_dismiss: false,
                        showProgressbar: false,
                        newest_on_top: true,
                        delay: 5000,
                        placement: {
                            from: "top",
                            align: "right",
                        },
                    }
                );
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            } else {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }
        },
        priceOptions(index = null) {
            let array = [];

            if (index !== null && this.hostings && this.hostings.length) {
                let object = this.hostings[index].pricing.EUR;

                for (const property in object) {
                    let obj = {};
                    let billingcycle;

                    if (
                        property == `annually` ||
                        property == `biennially` ||
                        property == `triennially`
                    ) {
                        if (property == `annually`) {
                            billingcycle = 1;
                        }

                        if (property == `biennially`) {
                            billingcycle = 2;
                        }

                        if (property == `triennially`) {
                            billingcycle = 3;
                        }

                        obj.bc = property;
                        obj.billingcycle = billingcycle;
                        obj.price = object[property];
                        array.push(obj);
                    }
                }
            }

            // console.log(array);
            return array;
        },
        getDomainSubtotal(index) {
            let subtotal = 0.0;
            let domain = this.domains[index];
            let billingcycle = domain.billingcycle;
            let addons = domain.addons;
            let price = domain.prices[billingcycle];

            if (addons && Object.entries(addons).length) {
                let email_forwarding = addons.email_forwarding;
                let id_protection = addons.id_protection;

                if (email_forwarding.purchasable && email_forwarding.checked) {
                    subtotal +=
                        parseFloat(email_forwarding.price) * parseFloat(billingcycle);
                }

                if (id_protection.purchasable && id_protection.checked) {
                    subtotal +=
                        parseFloat(id_protection.price) * parseFloat(billingcycle);
                }
            }

            this.domains[index].subtotal = parseFloat(price) + subtotal;

            return this.domains[index].subtotal.toFixed(2) ?? 0.0;
        },
        getHostingSubtotal(index) {
            let subtotal = 0.0;
            let hosting = this.hostings[index];
            let billingcycle = hosting.billingcycle;
            let addons = hosting.addons;
            let domainAddons = hosting.domain.addons;
            let domainPrice = hosting.domain.price;
            let price = 0.0;
            let bc;
            let prezzoDominio;

            if (billingcycle == 1) {
                price = this.hostings[index].pricing.EUR.annually;
                bc = `annually`;
            }

            if (billingcycle == 2) {
                price = this.hostings[index].pricing.EUR.biennially;
                bc = `biennially`;
            }

            if (billingcycle == 3) {
                price = this.hostings[index].pricing.EUR.triennially;
                bc = `triennially`;
            }

            if (domainPrice && hosting.domain.gratis != true) {
                prezzoDominio = parseFloat(domainPrice) * parseFloat(billingcycle);
                subtotal += prezzoDominio;
            }

            if (addons && addons.length) {
                for (let index = 0; index < addons.length; index++) {
                    const element = addons[index];

                    if (element.checked) {
                        // subtotal += parseFloat(element[bc]) * parseFloat(billingcycle);
                        subtotal += parseFloat(element[bc]);
                    }
                }
            }

            if (domainAddons && Object.entries(domainAddons).length) {
                let email_forwarding = domainAddons.email_forwarding;
                let id_protection = domainAddons.id_protection;

                if (email_forwarding.purchasable && email_forwarding.checked) {
                    subtotal +=
                        parseFloat(email_forwarding.price) * parseFloat(billingcycle);
                }

                if (id_protection.purchasable && id_protection.checked) {
                    subtotal +=
                        parseFloat(id_protection.price) * parseFloat(billingcycle);
                }
            }

            if (prezzoDominio) {
                this.hostings[index].domain.prezzoDominio = prezzoDominio.toFixed(2);
            } else {
                this.hostings[index].domain.prezzoDominio =
                    parseFloat(domainPrice).toFixed(2);
            }

            this.hostings[index].subtotal = parseFloat(price) + subtotal;

            return this.hostings[index].subtotal.toFixed(2) ?? 0.0;
        },
        getTotalDomains() {
            let total = 0.0;

            for (let index = 0; index < this.domains.length; index++) {
                const element = this.domains[index];
                let subtotal = element.subtotal;
                total += parseFloat(subtotal);
            }

            this.totalDomains = total.toFixed(2);
            return total.toFixed(2);
        },
        getTotalHostings() {
            let total = 0.0;

            for (let index = 0; index < this.hostings.length; index++) {
                const element = this.hostings[index];
                let subtotal = element.subtotal;
                total += parseFloat(subtotal);
            }

            this.totalHostings = total.toFixed(2);
            return total.toFixed(2);
        },
        getTotal() {
            let total = parseFloat(this.subtotal) + parseFloat(this.tax);
            this.total = total.toFixed(2);
            return total.toFixed(2);
        },
        getSubTotal() {
            let subtotal =
                parseFloat(this.totalDomains) + parseFloat(this.totalHostings);
            this.subtotal = subtotal.toFixed(2);
            return subtotal.toFixed(2);
        },
        getTax() {
            let subtotal = parseFloat(this.subtotal);
            let aliquota = this.iva;

            let iva = (subtotal * aliquota) / 100;
            this.tax = iva.toFixed(2);
            return iva.toFixed(2);
        },
        saveCart() {
            this.$v.$touch();

            if (this.$v.domains.$invalid || this.$v.hostings.$invalid) {
                this.scrollToTop(
                    `validationMessage`,
                    `Inserisci codici di migrazione (EPPCode / AuthInfo).`,
                    `warning`
                );
                this.invalidCart = true;
                this.$forceUpdate();
            } else {
                let loader = this.$loading.show({
                    container: null,
                    canCancel: false,
                    color: "#ffbf00",
                    backgroundColor: "#000000",
                    lockScroll: true,
                    isFullPage: true,
                    height: 128,
                    width: 128,
                    loader: "spinner",
                });

                axios
                    .post("/cart/axios", {
                        domains: this.domains,
                        hostings: this.hostings,
                        subtotal: this.subtotal,
                        tax: this.tax,
                        total: this.total,
                        totalDomains: this.totalDomains,
                        totalHostings: this.totalHostings,
                        coupon: this.coupon,
                        discount: this.discount,
                        from: "Cart.vue@saveCart()",
                    })
                    .then((response) => {
                        // console.log(response.data);
                        loader.hide();
                        window.location.replace("/cart/checkout");
                    })
                    .catch((errors) => console.log(errors));
            }
        },
    },
    watch: {},
    created() {
        this.prodotti = this.products.products.product;

        if (this.data) {
            try {
                if (Array.isArray(this.data.domains)) {
                    this.domains = this.data.domains ?? [];
                } else {
                    for (const property in this.data.domains) {
                        this.domains.push(this.data.domains[property]);
                    }
                }

                if (Array.isArray(this.data.hosting)) {
                    this.hostings = this.data.hosting ?? [];
                } else {
                    for (const property in this.data.hosting) {
                        this.hostings.push(this.data.hosting[property]);
                    }
                }
            } catch (error) {
                this.domains = this.data.domains ?? [];
                this.hostings = this.data.hosting ?? [];
                console.error(error);
            }
        }
    },
    mounted() {
        // this.$forceUpdate();
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    },
};
</script>